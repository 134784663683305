const messagesZh = {
	tracking_number: '货运追踪号',
	hello: 'Hello world',
	language: '语言',
	currency: '货币',
	copyright: '版权声明',
	allright: '版权所有。',
	sort: '排序',
	filter: '过滤',
	sort_by: '排序',
	refine_by: '过滤选项',
	price: '价格',
	average_rating: '平均评分',
	reviews: '评论',
	item_code: '产品编码',
	qty: '数量',
	register_enable: '注册账户激活',
	wholesale_prices: '批发价',
	add_to_cart: '加入购物车',
	add_to_favorites: '加入收藏',
	description: '描述',
	reviews: '评论',
	shipping_payment: '货运 & 支付',
	customer_who_also_bought: '买了这个产品的人还买了什么',
	add_review: '增加评论',
	view_all_review: '查看所有评论',
	you_must_chosen: '您必须选择',
	cart: '购物车',
	discount_codes: '折扣',
	subtotal: '小计',
	sub_weight: '总重',
	sub_volume: '总体积',
	shipping_cost: '运费',
	discount: '折扣',
	grand_total: '总计',
	proceed_to_pay: '继续付款',
	checkout: '下单页面',
	welcome_to_checkout: '欢迎来到结帐，填写下面的字段来完成您的购买！',
	already_registered: '已经注册？ 点击此处登录',
	shipping_address: '发货地址',
	new_address: '新地址',
	first_name: '名',
	last_name: '姓',
	email_address: '邮箱',
	EMAIL: '联络电邮',
	emailAddress: '电邮地址',
	telephone: '电话',
	street: '街道',
	country: '国家',
	state: '省',
	city: '城市',
	zip_code: '邮编',
	create_an_account: '注册',
	password: '密码',
	confirm_password: '确认密码',
	payment_method: '支付方式',
	shipping_method: '货运方式',
	coupon_codes: '优惠券代码（可选）',
	enter_your_coupon_code: '输入您的优惠券代码',
	review_your_order: '查看你的订单',
	place_order_now: '现在下单',
	return_policy: '退货条约',
	privacy_policy: '隐私条约',
	contact_us: '联络我们',
	about_us: '关于我们',
	my_favorite: '我的收藏',
	my_order: '我的订单',
	shopping_cart: '购物车',
	account: '账户',
	home: '首页',
	sign_in: '登录',
	forgot_password: '忘记密码？',
	register: '注册',
	login: '登录',
	register_account: '注册账户',
	my_account: '我的账户',
	logout: '退出登录',
	edit_account: '编辑账户',
	change_password: '更改密码',
	add_new_address: '增加新地址',
	default_address: '默认地址',
	name: '名字',
	address: '地址',
	operation: '操作',
	customer_address: '货运地址',
	reorder: '重新下单',
	view_order: '查看订单',
	order_increment_id: '订单号',
	date: '日期',
	operate: '操作',
	customer_order: '我的订单',
	order_status: '订单状态',
	order_date: '订单日期',
	shipping_address: '货运地址',
	shipping_method: '货运方式',
	payment_method: '支付方式',
	items_ordered: '订单产品',
	product_image: '产品图片',
	grand_total: '总计',
	product_info: '产品信息',
	product_review: '产品评论',
	product_favorite: '产品收藏',
	send_authorization_code: '发送授权码',
	forget_password: '忘记密码',
	click_here: '点击这里',
	to_login: '去登陆',
	reset_accout_success: '重置密码成功，您可以',
	reset_password_success: '充值密码成功',
	forget_password_send_success: '充值密码发送成功',
	we_send_message_to_your_email: '我们已经给您的邮箱发送了一封邮件',
	follow_the_instructions_provided: '请按照邮件中提供的说明重置您的密码。',
	donot_receive_email: '没有收到我们的邮件？',
	click_here_to_retry: '点击这里重新发送邮件',
	check_your_bulk_junk_email_folder: '检查您的垃圾邮件文件夹。',
	confirm_your_identity_to_reset: '如果你仍然无法找到它，请确认你的身份重置密码',
	click: '点击',
	support_center: '帮助中心',
	for_help: '寻求帮助',
	reset_password: '重置密码',
	our_email_address: '我们的邮箱地址',
	contacts: '联系我们',
	submit: '提交',
	captcha: '验证码',
	review: '评论',
	rate: '评星',
	summary: '概要',
	name: '姓名',
	product_review: '产品评论',
	add_review: '新增评论',
	reviews: '评论',
	thank_you_for_purchase: '您的订单，我们已经收到，感谢您的购买',
	your_order_is: '您的订单号',
	receive_an_confirm_email: '您将收到一份订单确认电子邮件，其中包含您订单的详细信息以及跟踪其进度的链接。',
	continue_shopping: '继续购物',
	order_remark: '订单备注 (可选)',
	fill_order_remark: '你可以在下面填写您的备注信息',
	average_rating: '平均评分',
	stars_5: '5 星',
	stars_4: '4 星',
	stars_3: '3 星',
	stars_2: '2 星',
	stars_1: '1 星',
	arm: '主件',
	accessories: '配件',
	styles: '款式',
	material: '材质',
	Customization: '自选組合',
	pleaseSelect: '请选择',
	sorting: '综合排序',
	category: '类别',
	gem: 'Gem',
	Price: '价格',
	getCode: '获取验证码',
	securityCode: '电邮验证码',
	signUp: '注册',
	please: '请输入',
	pleases: '请选择',
	terms: '条款和条件',
	privacy: '隐私政策使用',
	Already: '已经注册？',
	and: '和',
	Agr1: '建立帐户即代表您同意我们根据 {n} 的',
	Agr2: ' 使用您的数据资料',
	agr2: '如果您不希望继续收到我们的推广资料，请勾选此框',
	LOGIN: '登录',
	LOGINF: '请登录您的账户',
	LOGINFA: '创建一个帐户',
	LOGINFB: '建立新账户以获取额外优惠，快速结账以及订单查询及追踪',
	FORGOT: '忘记密码?',
	FORGOTF: '若要重置密码，请输入电子邮件地址',
	ResetPassword: '新密码',
	ResetPasswordA: '确认新密码',
	ProceedLog: '继续登录',
	Information: '个人信息',
	Orders: '我的订单',
	Appointment: '我的预约',
	SignOut: '退出登录',
	LAA: '欢迎登入,畅享以下购物体验：',
	LAB: '更轻鬆存取订购记录、已储存产品及其他资料',
	LAC: '使用已储存的出货及帐单资料，更快结帐',
	LAD: '个人化设计记录',
	LAE: '登录',
	LAF: '创建一个帐户',
	SizeElectionGuide: '尺码选择指南',
	addCart: '加入购物车',
	CheckOut: '结账',
	Recommend: '向您推荐',
	Recently: '猜你也喜欢',
	SearchComments: '搜索评论',
	QUERY: '搜索',
	serviceFA: '寻找区内的{n}体验服务店，预约私人的珠宝顾问服务',
	serviceFB: '查找',
	serviceFC: '香港本地包邮',
	serviceFD: '精美礼盒包装',
	serviceFE: '免费刻字',
	serviceFF: '钻石证书',
	Details: '基本信息',
	ProductDetails: '产品详细信息',
	Descriptionworks: '工作的描述',
	OnlineConsultan: 'whatsAPP',
	whatsApp: 'whatsApp',
	ShoppingCart: '购物车',
	currentShopping: '当前购物车共',
	currentWorks: '件作品',
	ChooseAll: '全选',
	Validation: '附加证书',
	CHANGE: '更改',
	TOTAL: '总计',
	TAXESDUTIES: '税项：',
	TAXESDUTIESFA: '对于美国及香港的订单,以上总金额已包含有关的入口关税。',
	TAXESDUTIESFB: '对于美国及香港以外的海外订单,入口关税及其他清关费用将会由快递公司在货物送抵时向您收取。',
	Amount: '数量',
	OrderInformation: '订单信息',
	Items: '件',
	Subtotal: '小计',
	PromotionCode: '促销代码',
	promocode: '输入促销代码获得折扣',
	cancel: '取消',
	CONFIRM: '确认',
	NOTE: '注意',
	NOTEFA: '特价货品/已刻字产品/定制产品均不享有退货及换货服务',
	Need: '需要帮忙?',
	NeedFA: '退换货政策',
	NeedFB: '有关定制产品，我们会电邮给你以确认定制详情。',
	Remove: '删除',
	Viewall: '查看所有',
	Select: '已选择',
	ShoppingCart: '购物车',
	cartEmpty: '你的购物车是空的',
	MyDesign: '我的设计',
	designYet: `你还没有开始你的设计。`,
	Starthere: '从这里开始!',
	GoStudio: '去3D {n}工作室',
	Wishlist: '愿望清单',
	listEmpty: '你的愿望清单是空的',
	Accountinformation: '物流信息',
	accountOrderFA: '登录帐户将用于跟踪您的订单状态',
	accountOrderFB: '订单一概不得运往邮政信箱。',
	accountOrderFC: '请提供一个送件时将有人签收的街头地址。',
	CreateAccount: '创建一个帐户',
	Email: 'E-mail',
	verificationCode: '获取验证码',
	SecurityCode: '验证码',
	Shippinginformation: '配送信息',
	StandardLogistics: '标准物流',
	PickStore: '到店取货',
	InstructionsStore: '邮寄到门店说明',
	Theeditor: '编辑',
	Title: '称呼',
	FirstName: '姓',
	LastName: '名称',
	PhoneNumber: '手机号码',
	StateProvince: '州/省',
	PostalCode: '邮编',
	AddressLine1: '详细地址 1',
	AddressLine2: '详细地址 2',
	PaymentMethod: '支付方式',
	PromoCode: '优惠码',
	PromoCode1: '优惠金额',
	Postscript: '附言',
	PostscriptFA: '如果您还有其他要求，请在这里填写',
	PaymentAgrFA: '已清楚并接受有关',
	PaymentAgrFB: '条款和条件',
	PaymentAgrFC: '包括',
	PaymentAgrFD: '付款',
	PaymentAgrFE: '退货政策',
	PaymentAgrFF: '勾选此方框，即表示我同意本',
	PaymentAgrFG: '隐私政策',
	PaymentAgrFH: '并同意将所提供的信息用于{n}。',
	paymentworks: '已选{count}件作品进行结算和支付',
	ShippingInsurance: '运输和保险',
	Findme: '在我附近找一家商店',
	selectLocation: '请选择位置',
	storesfound: '已为您找到 {count} 家门店',
	CHOOSE: '选择',
	YourAccount: '您的账户',
	MyDetails: '个人信息',
	Orders: '我的订单',
	Return: '退货退款',
	MyAppointment: '我的预约',
	Account: '账户信息',
	OAA: '您的邮箱',
	OAC: '您当前的邮箱',
	OAD: '您的密码',
	OAE: '如要修改密码，请在下方重新输入.',
	OAF: '您的新密码',
	OAG: '再次确认密码',
	OAH: '您现在的电话号码是',
	OAI: '新号码',
	SAVE: '保存',
	Birthday: '生日',
	Relationship: '感情状态',
	Unmarried: '未婚',
	married: '已婚',
	engagement: '订婚',
	anniversary: '你们的结婚纪念日是什么时候?',
	OrderNo: '订单号',
	Custom: '定制作品',
	Existing: '现成作品',
	Waiting: '等待付款',
	WaitingFA: '您的订单已创建，请继续完成付款；如未能在60分钟内付款，订单将自动取消。',
	WaitingFB: '支付处理中',
	WaitingFC: '付款成功',
	WaitingFD: '欺诈',
	InReview: '在审查中',
	InReviewFA: '我们的客户服务团队已经收到了您的订单，目前正在为您审查中。',
	InReviewFB: '请耐心等待',
	wSend: '订单已确认',
	wSendFA: '客服已经确认您的订单。我们会尽快安排订单处理并发货给您。',
	wGoods: '您的订单已经发货',
	wGoodsFA: '请检查发货人的跟踪号查看物流状态。',
	wreceived: '您的订单已收货',
	wEnd: '您的订单完成了',
	wEndFA: '签署并接收订单。感谢您在{n}购物，希望您喜欢您的新珠宝！请给我们您的意见和建议。我们希望很快再见到您！',
	wExpired: '您的订单已取消',
	wExpiredFA: '您的订单已取消',
	wExpiredFB: '已取消',
	paymentOverdue: '您的付款已过期',
	orderComplete: '您的订单已完成',
	orderShipped: '您的订单已经发货',
	OrderConfirmed: '订单已确认',
	PleasePatient: '请耐心等待',
	PickupCode: '取件代码',
	RemainingTime: '剩余付款时间',
	ReturnShopping: '回到购物',
	WRITEREVIEW: '写一个评论',
	ConfirmReceipt: '确认收货',
	applyRefund: '申请退款',
	CREATE: '创建',
	PAYMENT: '付款',
	ORDERCOMFIRMED: '审查',
	Shipped: '发货',
	Complete: '完成',
	LogisticsInfo: '物流信息',
	CustomDesign: '自定义设计',
	Setting: '设置',
	CenterStone: '凋刻',
	Engravingfee: '钻石',
	gender0: '保密',
	gender1: '先生',
	gender2: '女士',
	timelineFA: '* Here shows the standard logistics related information, dynamic configuration by the background',
	Certification: '额外的认证(可选)',
	RefundFA: '申请退货/退款',
	RefundFB: '收到物品后({count})天内生效，定制物品除外。提交您的申请，我们的客服代表将回复您的后续行动。',
	RefundFC: '服务类型',
	RefundFD: '退换货原因',
	RefundFE: '详细描述',
	RefundFF: '图片资料',
	RefundFG: '为了帮助我们更好的解决问题，请上传图片',
	RefundFH: '退回类型',
	salesAfterFA: '在审查中',
	salesAfterFB: '我们的客户服务团队已经收到您的退款请求，目前正在为您审查它',
	salesAfterFC: '退款被拒绝',
	salesAfterFD: '很抱歉，您的请求被拒绝了。如果您有任何问题，请联系我们的客服代表。我们希望很快再见到您！',
	salesAfterFE: '退款成功',
	salesAfterFF: '您的退款已被批淮，所要求的金额已通过您原来的支付方式返回给您。请注意查收',
	salesAfterFG: '缺货',
	// salesAfterFH: '我们非常抱歉。每颗砖石都是独一无二，您选择的钻石已经断货，我们已经选择了几颗价格相识，4C的钻石给您替换，仍然不能满足您的要求，我们将通过您原来的付款方式将金额原路退回。谢谢您的理解，希望很快能见到您！',
	salesAfterFH: '非常抱歉您的退货申请被驳回。根据我们的检测结果，我们无法接受您退回的商品。如果您有任何问题或疑虑，请随时与我们联系，我们会尽力为您解决。',
	salesAfterFI: '商家回复：',
	salesAfterFJ: '审查通过',
	salesAfterFK: '审查通过,等待商家退款',
	salesAfterFL: '退款已取消',
	applyTime: '申请时间',
	remark: '备注',
	Actual: '实际退款金额',
	rActual: '应退款金额',
	Application: '我的申请信息',
	VisitingAppointment: '门店预约',
	RequestAppointment: '预约: 选择您喜欢的店铺，在友好的工作人员的支持下享受定制设计体验。填妥表格并递交，我们会以电话或电邮与您联络确认。',
	Visitingappointment: '联系方式',
	Reservationinformation: '预订信息',
	Appointmenttime: '预约日期及时间',
	Pleaseelaborate: '请详细说明我们可以如何帮助你。',
	Yourselection: '您的选择',
	DoOptional: '你已经有想法了吗?(可选)',
	Chooseyou: '选择一种吸引你的风格。',
	CONFIRM: '确认',
	Businesshours: '营业时间',
	aFA: '我们会与您联系',
	aFB: '我们已收到您的请求，并将在48小时内与您联系，安排店内预约。',
	aFC: '您的选择',
	aFD: '预约成功',
	aFE: '你好!',
	aFF: '欢迎使用{title}网上店铺预订服务。您的“{count}-店”申请已获批准。',
	aFG: '我的选择',
	aFH: '店铺未营业!',
	Purpose: '备注',
	appointmenttime: '预约时间',
	CHOOSESETTING: '选择这个设置',
	Modify: '修改',
	Compare: '比较',
	DCompare: '取消比较',
	law: '网站政策和法律声明',
	edu: '教育',
	problem: '常见问题',
	color: '颜色',
	clarity: '净度',
	cut: '切工',
	symmetry: '对称',
	polish: '抛光',
	fluor: '荧光',
	drill: '强力搜钻',
	replace: '重新选择',
	latest: '最新',
	weight: '重量',
	Ingem: '换宝石',
	shape: '形状',
	Results: '结果',
	Comparison: '比较',
	indexFA: '自定义分类',
	indexFB: '独特而有品位的物品，让你送给你的爱人，送给你的朋友，或搭配不同的时尚风格',
	indexFC: '设计你自己的戒指',
	indexFD: '提供专属定制服务，从订婚钻戒到结婚戒指，自由创造属于你的独特爱情故事',
	indexFE: '从戒指款式开始',
	indexFF: '从钻石开始',
	indexFG: '特色介绍',
	indexFH: '把你爱的光芒放在命运的红线上，让我们的故事不一样',
	indexFI: '发现更多＋',
	indexFJ: '自定义设计',
	indexFK: '设计好了',
	indexFL: '热销单品',
	indexFM: '选购钻石',
	indexFN: '请选择你的送货目的地和货币',
	indexFO: '从戒托开始',
	Countries: "运往",
	dialogVisibleFA: '戒指尺码选择指南',
	dialogVisibleFB: '拿一根不可拉伸的绳子、丝带、线或一张薄纸。把它舒适的绕在无名指或其他手指上，靠近指关节',
	dialogVisibleFC: '仔细地用钢笔在琴弦重迭的地方做记号。为了达到更好的准确性，尽可能做最小的标记',
	dialogVisibleFD: '打开标签的长度。用尺子测量毫米或英寸。在下面输入您的测量值',
	dialogVisibleFE: '输入您所测量的值',
	dialogVisibleFF: '计算方式',
	dialogVisibleFG: '测量结果：您的尺码是',
	dialogVisibleFH: '你想把测量好的尺寸存到您的账户中吗？',
	dialogVisibleFI: '是的，我想保存他',
	dialogVisibleFJ: '确认',
	failure: '失效',
	Records: '记录',
	Recommends: '推荐',
	Recommends1: '搭配组合',
	ProductDescription: '产品描述',
	User: '用户',
	OrderDetail: '订单详情',
	Comments: '评论',
	Score: '评分',
	Review: '评价',
	Anonymously: '是否匿名',
	POST: '提交',
	CommentsFA: '这个产品是如何选择的?',
	CommentsFB: '我选择它作为一个惊喜/礼物',
	CommentsFC: '我和我的搭档合作',
	CommentsFD: '我的伴侣为她自己选的',
	CommentsFE: '这是我给自己选的礼物',
	CommentsFF: '从1到10，你有多大可能向你的朋友或同事推荐Ju mart ?',
	CommentsFG: '评论',
	CommentsFH: '这个评论对你有用吗?',
	ringSetting: '戒托',
	CertificateNumber: '证书编号',
	FindAStore: '体验服务店',
	Size: '尺寸',
	WEACCEPT: '我们接受以下付款方式',
	cta: '请勾选协议',
	PAYMENTSUCCESS: '付款成功',
	PAYMENTSUCCESS1: '我们的客户服务团队已经收到了您的订单，目前正在审核。',
	PAYMENTSUCCESS2: '继续购买',
	PAYMENTFAILURE: '付款失败',
	PAYMENTFAILURE1: '付款失败，请重新付款',
	PAYMENTFAILURE2: '继续付款',
	PAYMENTSUCCESSD: '订单详情',
	PAYMENTSUCCESSR: '返回首页',
	JUMARTEMAIL: 'info@JU-MART.com',
	PAYFA: '配送信息不能为空！',
	PAYFB: '请选择门店！',
	PAYFB: '请选择门店！',
	store: '门店',
	MoreInformation: '更多信息',
	Prompt: '提示',
	Logged: `您还没有登录，是否前往登录？`,
	Exceeding: '字母数字组合，长度最多20个字符',
	Accept: '接受',
	Reject: '拒绝',
	alertdialog: '我们使用 Cookie 为访客提供最佳的网站体验。 其中包括分析、功能与定位Cookie，这些项目对于我们的市场行销工作也会有所助益。 按一下「接受」按钮同意使用 Cookie。 若要撤回非必要的 Cookie，按一下「拒绝」。',
	ExperienceCustomization: '体验定制功能',
	MAKEAAPPOINTMENT: '预约',
	ExcludingTaxes: '不含税',
	ExcludingTaxes1: '不含税',
	ExcludingTaxes2: '不含税',
	GoodsRefunded: '商品不能申请退款',
	subscribeFA: '获得专享优惠和新消息',
	subscribeFB: '你的电子邮件',
	subscribeFC: '登记',
	subscribeFD: '感谢您的订阅！',
	ExhibitionChain: 'Exhibition Chain type',
	Choose: '选择',
	Cancels: '取消',
	FindNear: '离我最近的客户服务中心',
	YouDesigned: '你目前已经设计了',
	ViewAll: '查看所有',
	CompareDesigns: '比较设计',
	YouCrrently: '你现在有',
	Hi: '嗨',
	Nologistics: '没有物流信息',
	Exploremore: '探索更多',
	originalPrice: '原价',
	Return: '返回列表',
	designFA: '选择一个',
	designFB: '设置',
	designFC: '钻石/宝石',
	designFD: '钻石/宝石',
	designFE: '完整的',
	designFF: '选择这个钻石',
	designFG: '该设置无对应钻石',
	Location: '地址',
	Coffee: '咖啡',
	Browsinghistory: '浏览记录',
	NotFun: '产品不存在或已下架',
	ReceiveGift: '领取赠品',
	PaymentAgrFZ: '請注意您購買的是包含了贈品的聯動產品，一旦完成購買，將不能進行退錢、退貨或是換貨，請確認後再購買。',
}
export default messagesZh
