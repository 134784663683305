<template>
	<div class="alertdialog flexJA flexSb" v-if="!alertdialog">
		<div class="left">{{$t('message.alertdialog')}}</div>
		<div class="right flexJA">
			<div id="onetrust-button-group"><button @click="bindAlertdialog(2)">{{$t('message.Reject')}}</button> <button @click="bindAlertdialog(1)">{{$t('message.Accept')}}</button></div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				alertdialog: ''
			}
		},
		mounted() {
			this.alertdialog = localStorage.getItem('alertdialog');
		},
		methods: {
			bindAlertdialog(e) {
				this.alertdialog = e;
				localStorage.setItem('alertdialog', e);
			}
		}
	}
</script>

<style scoped lang="scss">
	.alertdialog {
		left: 0;
		bottom: 0;
		z-index: 9999;
		padding: 30px;
		position: fixed;
		background: #ffffff;
		width: calc(100% - 60px);
		box-shadow: 0 0 18px rgb(0 0 0 / 20%);
		
		.left {
			flex: 1;
			font-size: 13px;
		}
		
		.right {
			margin-left: 300px;
			
			button {
				color: #ffffff;
				font-size: 13px;
				padding: 8px 20px;
				margin-left: 20px;
				font-weight: 600;
				cursor: pointer;
				background-color: #3345CB;
				
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
</style>