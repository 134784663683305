<template>
	<div id="app" class="flexJA flexDc" :class="{HeightFlag: HeightFlag}">
		<Header @bindHeight="bindHeight" @bindLanguage="bindLanguage" v-if="hideHF"></Header>
		<router-view ref="main" v-if="isRouterAlive" @bindClick="bindClick" @bindHeight="bindHeight" @hideHF="hideHF = false"/>
		<Footer v-if="hideHF"></Footer>
		<DialogVisible ref="DialogVisible" ></DialogVisible>
		<Alertdialog></Alertdialog>
		
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import Alertdialog from '@/components/Alertdialog.vue'
	import DialogVisible from '@/components/DialogVisible'
	
	export default {
		provide() {
			return{
				reload: this.reload
			}
		},
		
		metaInfo(){
			let meta = this.$store.state.metaInfo;
			// console.log(this.$route, meta, this.TITLE)
			let title = meta.title == 'undefined ' ? this.TITLE : (meta.title || this.TITLE);
			let Keywords = meta.Keywords || this.TITLE;
			let description = meta.description || this.TITLE;
			if(!this.$route.meta.title) {
				title = this.TITLE;
				Keywords = this.TITLE;
				description = this.TITLE;
			}
		    return {
		        title: title,
		        meta: [
		            {name: "Keywords", content: Keywords}, 
					{name: "description", content: description}
		        ]
		    }
		},
		data(){
			return{
				hideHF: true,
				HeightFlag: false,
				isRouterAlive: true
			}
		},
		computed: mapState([
			'homeBase',
			'userInfo',
			'TITLE'
		]),
		watch: {
			'$route' (to,from) {
				setTimeout(e=>{
					document.body.scrollTop = 0 + Math.random();
					document.documentElement.scrollTop = 0 + Math.random();
				})
			}
		},
		mounted() {
			console.log(process.env.VUE_APP_TITLE || 'Bless Jewellery');
			if(!localStorage.getItem('fullPath-type')) localStorage.setItem('fullPath', '');
			localStorage.setItem('fullPath-type', '');
			this.ListenStorage();
			this.$store.commit('gotToken');
			if(localStorage.getItem('homeBase')) this.$store.commit('gotHomeBase', JSON.parse(localStorage.getItem('homeBase')));
			this.$store.dispatch('getHomeBase');
			if(localStorage.getItem('token')) this.$store.dispatch('getuserInfo');
			
			
			this.resize();
			window.onresize = () => {
				this.resize();
			}
		},
		methods: {
			resize() {
				let userAgentInfo = navigator.userAgent;
				let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
				let getArr = Agents.filter(i => userAgentInfo.includes(i));
				let {pathname, search} = location;
				console.log(pathname, search);
				if(getArr.length) {
					let URL = process.env.VUE_APP_H5;
					let oid = this.getQueryString('tid');
					let tid = this.getQueryString('aid');
					let link = {
						'/article': `${URL}articleList?oid=${oid}&tid=${tid}`,
						'/designDetails': `${URL}fixedDetails${search}`,
						'/variableDetails': `${URL}variableDetails${search}`,
						'/commodityDetails': `${URL}suitDetails${search}`,
						'/details': `${URL}goodsDetails${search}`,
					}
					location.href = link[pathname] ? link[pathname] : URL;
				}
			},
			getQueryString(name) {
			    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
			    var r = window.location.search.substr(1).match(reg);
			    if (r != null) {
			        return unescape(r[2]);
			    }
			    return null;
			},
			bindClick(n) {
				this[n]();
			},
			bindHeight(e) {
				this.HeightFlag = e;
			},
			bindLanguage() {
				this.$refs.main.LanguagesChang();
			},
			bindDialogVisible() {
				this.$refs.DialogVisible.bindDialog();
			},
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(()=>{
					this.isRouterAlive = true;
				})
			},
			ListenStorage() {
				const setItem = localStorage.setItem;
				localStorage.setItem = function (name, value) {
					setItem.apply(this, arguments);
					var event = new Event('setItem');
					event.key = name;
					event.value = value;
					window.dispatchEvent(event);
				}
				const removeItem = localStorage.removeItem;
				localStorage.removeItem = function (name, value) {
					removeItem.apply(this, arguments);
					var event = new Event('removeItem');
					event.key = name;
					event.value = value;
					window.dispatchEvent(event);
				}
				window.addEventListener('setItem', function (e) {
					console.log('setItem:',e.key)
				})
				window.addEventListener('removeItem', function (e) {
					console.log('removeItem:',e.key)
				})
			}
		},
		components: {
			Header,
			Footer,
			Alertdialog,
			DialogVisible
		}
	}
</script>

<style lang="scss">
	@font-face {
	    font-family: Helvetica;
	    src: url('./assets/font/Helvetica.ttc')
	}
	
	html, body {width: 100%; height: 100%; background: #ffffff; font-family: sans-serif miui Helvetica;}
	html,body,div,header,footer,nav,section,table,tr,th,td,ul,ol,li,dl,dt,dd,em,i,b,p,s,h1,h2,h3,h4,h5,h6,pre,img,form,fieldset,legend,input,textarea,select,button{margin:0; padding:0; font-weight:normal;font-size: 14px;}
	ol, ul{list-style:none;}
	em {font-style:normal; }
	img{border:0; display:block;}
	i, del, em, span{font-style:normal;}
	h1, h2, h3, h4, h5, h6{font-size:100%;}
	textarea{resize:none;-webkit-appearance: none;}
	input, button, select, textarea{outline:none; border:none; border-radius:0;} 
	input[type="button"], input[type="submit"], input[type="reset"] {-webkit-appearance: none;}
	a{color:#121212; text-decoration:none; -webkit-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -ms-transition: all 0.4s ease; -o-transition: all 0.4s ease; transition: all 0.4s ease;cursor:pointer !important;}
	
	::-webkit-input-placeholder {color:#9f9f9f;}
	:-moz-placeholder {color:#9f9f9f;}
	::-moz-placeholder {color:#9f9f9f;}
	:-ms-input-placeholder {color:#9f9f9f;}
	
	.uppercase {text-transform: uppercase;}
	.wh100 {width: 100%; height: 100%;}
	.disabled {cursor: default; pointer-events: none;}
	.banLine {overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
	.banLines {overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
	.pc {position: absolute;top:50%;left: 50%;transform: translate(-50%, -50%);}
	.center {text-align: center;}
	.cursor {cursor: pointer;}
	.mb20 {margin-bottom: 20px !important;}
	
	.flexJA {display: flex;align-items: center;flex-direction: row;justify-content: center;}
	.flexDc {flex-direction: column;}
	.flexFs {justify-content: flex-start;}
	.flexFe {justify-content: flex-end;}
	.flexSb {justify-content: space-between;}
	.flexSa {justify-content: space-around;}
	.flexAE {align-items: baseline;}
	
	#app {width: 100%;min-width: 1400px;min-height: 100%;margin: 0 auto;}
	.content {flex: 1;width: 100%;}
	.HeightFlag {height: 100%;overflow: hidden;}
	
	::-webkit-scrollbar {width: 4px;height: 4px;}
	::-webkit-scrollbar-thumb {cursor: pointer; background: #bfbfbf;}
	
	.breadcrumb {
		width: 8.333333rem;
		margin: 0.208333rem auto;
		
		.el-breadcrumb__inner {
			font-size: 0.072917rem !important;
		}
	}
	
	.loading {width: 100%; height: 100px;margin-bottom: 0.416667rem;color: #999;}
	
	.el-select-dropdown__empty{
		font-size: 12px !important;
		min-width: 60px;
	}
	.el-select-dropdown__item {
		font-size: 12px !important;
	}
	
</style>
