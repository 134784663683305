<template>
	<!-- 未登录注册 -->
	<div class="login">
		<!-- 未登录 -->
		<el-popover placement="bottom" width="300" trigger="hover" v-if="!token">
			<div class="text1">{{$tc('message.LAA', TITLE)}}</div>
			<li class="text2">{{$t('message.LAB')}}</li>
			<li class="text2">{{$t('message.LAC')}}</li>
			<li class="text2">{{$t('message.LAD')}}</li>
			<div class="btn1 flexJA" @click="bindNav(`/login`)">{{$t('message.LAE')}}</div>
			<div class="btn2 flexJA" @click="bindNav(`/register`)">{{$t('message.LAF')}}</div>
			<el-button slot="reference" class="wh100">未登录</el-button>
		</el-popover>
		<!-- 已登录 -->
		<el-popover placement="bottom" width="200" trigger="hover" v-if="token">
			<div class="text1">{{$t('message.Hi')}}, {{userInfo.firstname}} {{userInfo.lastname || ''}}</div>
			<div>
				<router-link class="btn3 flexJA" to="/myOrder/message?type=1">{{$t('message.Information')}}</router-link>
				<router-link class="btn3 flexJA" to="/myOrder/order?type=2">{{$t('message.Orders')}}</router-link>
				<router-link class="btn3 flexJA" to="/myOrder/appointment?type=4">{{$t('message.Appointment')}}</router-link>
				<div class="btn3 flexJA" @click="bindExit">{{$t('message.SignOut')}}</div>
			</div>
			<el-button slot="reference" class="wh100">已登录</el-button>
		</el-popover>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
			}
		},
		computed: mapState([
			'token',
			'userInfo',
			'TITLE'
		]),
		watch: {
			
		},
		methods: {
			bindNav(url) {
				localStorage.setItem('fullPath', this.$route.fullPath);
				this.$router.push(url);
			},
			bindExit() {
				localStorage.removeItem('token');
				localStorage.removeItem('userInfo');
				this.$store.commit('exit')
				this.$router.push("/login");
			}
		}
	}
</script>

<style scoped lang="scss">
	.login {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		
		.el-button {
			opacity: 0;
		}
	}

	.el-select {
		width: 300px;
	}

	::v-deep .el-input__inner {
		border-radius: 0;
	}

	.el-select-dropdown__item {
		text-align: center;
	}

	.text1 {
		font-size: 16px;
		font-weight: 700;
		margin-top: 10px;
		color: #666666;
		word-break: break-word;
	}

	.text2 {
		font-size: 16px;
		color: #666666;
		margin: 15px 0 6px;
		word-break: break-word;
	}

	.btn1 {
		width: 300px;
		height: 40px;
		font-size: 16px;
		font-weight: 700;
		color: #FFFFFF;
		background: #3345CB;
		margin-top: 30px;
		margin-bottom: 15px;

		&:hover {
			opacity: 0.8;
		}
	}

	.btn2 {
		width: 300px;
		height: 40px;
		font-size: 18px;
		font-weight: 700;
		color: #666666;
		background: #fafafa;
		margin-top: 30px;
		margin-bottom: 15px;
		box-sizing: border-box;
		border: 1px solid #E4ECF1;

		&:hover {
			opacity: 0.5;
		}
	}

	.btn3 {
		width: 220px;
		height: 50px;
		font-size: 16px;
		color: #666666;
		margin-left: -10px;
		border-bottom: 1px solid #E4ECF1;

		&:hover {
			background: #3345cb;
			color: #ffffff;
		}

		&:nth-child(1) {
			margin-top: 20px;
		}

		&:nth-child(4) {
			border-bottom: none;
		}
	}
</style>
