import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import $api from '@/utils/api'
import { MessageBox } from 'element-ui';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		rate: 1,
		token: '',
		TITLE: process.env.VUE_APP_TITLE || 'Bless Jewellery',
		showHeaderLogin: false,
		config: {
			countries: '',
			countriesCode: '',
			language: '',
			languageCode: '',
			currency: '',
			currencyCode: ''
		},
		topNav: [],
		langList: [],
		currencyList: [],
		homeBase: {},
		userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
		bottomNav: {},
		country: [],
		countryArr: [],
		stateArr: [],
		cartList: [],
		cartInfo: {},
		sum: '' || 0,
		currency: {},
		designData: {},
		designList: [],
		favoriteList: [],
		addressList: [],
		keywords: null,
		cartLoading: 0,
		breadcumbs: {},
		goodsBreadcumb: {},
		metaInfo: {
			title: process.env.VUE_APP_TITLE || 'Bless Jewellery',
			Keywords: process.env.VUE_APP_TITLE || 'Bless Jewellery',
			description: process.env.VUE_APP_TITLE || 'Bless Jewellery'
		}
	},
	mutations: {
		exit(state) {
			state.token = '';
			state.cartList = [];
			state.designList = [];
			state.favoriteList = [];
			state.addressList = [];
		},
		NotFun() {
			console.log(router)
			router.replace('/NotFun')
		},
		gotMetaInfo(state, payload) {
			console.log(payload)
			state.metaInfo = payload;
		},
		gotShowHeaderLogin(state, payload) {
			state.showHeaderLogin = payload;
		},
		gotuserInfo(state, payload) {
			state.userInfo = payload;
			console.log(111111111, payload)
		},
		gotToken(state, payload) {
			state.token = localStorage.getItem('token') || '';
			if (state.token) {
				this.dispatch('getAddressIndex');
				this.dispatch('getCartList');
				this.dispatch('getFavorite');
				this.dispatch('getDesignList');
				this.dispatch('getAddressList');
			}
			// this.dispatch('getCatalogsearch');
		},
		gotHomeBase(state, payload) {
			payload.taxes_duties = payload.taxes_duties ? payload.taxes_duties.replace(/\n/g, '<br/>') : '';
			state.homeBase = payload;
			state.topNav = payload.top_nav;
			state.bottomNav = payload.bottom_nav;
			state.langList = payload.lang.langList;
			state.country = payload.country;
			state.currencyList = payload.currency.currencyList;
			this.commit('gotCountries', state.country);
			this.commit('gotLanguage', payload.lang.currentLang);
			this.commit('gotCurrency', payload.currency.currentCurrency);
		},
		gotLanguage(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : '';
			for (let i in state.langList) {
				if (state.langList[i].code == (o.languageCode || payload)) {
					state.config.languageCode = state.langList[i].code;
					state.config.language = state.langList[i].languageName;
				}
			}
		},
		gotCurrency(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : '';
			console.log(o, payload)
			for (let i in state.currencyList) {
				if (state.currencyList[i].code == (o.currencyCode || payload)) {
					// console.log(state.config)
					state.config.currencyCode = state.currencyList[i].code;
					state.config.currency = state.currencyList[i].code + ' ' + state.currencyList[i].symbol;
				}
			}
		},
		gotCountries(state, payload) {
			let o = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : '';
			for (let i in state.country) {
				if (state.country[i].country_symbol == o.countriesCode) {
					state.config.countriesCode = state.country[i].country_symbol;
					state.config.countries = state.country[i].country_code;
				}
			}
		},
		gotAddressIndex(state, payload) {
			state.stateArr = payload.address.stateArr;
			state.countryArr = payload.address.countryArr;
			// this.commit('gotCountries', payload);
		},
		gotFavorite(state, payload) {
			state.favoriteList = payload;
		},
		gotDesignList(state, payload) {
			state.designData = payload;
			let coll = payload.coll;
			for (let i in coll) {
				// if(!coll[i].hasOwnProperty('compare')) coll[i].compare = false;
				if (typeof coll[i].info == 'string') coll[i].info = JSON.parse(coll[i].info);
				if (coll[i].info) {
					if (coll[i].info.sku && coll[i].info.skuLanguages) {
						coll[i].info.sku = coll[i].info.skuLanguages[state.config.languageCode];
					}
				}
			}
			console.log(coll)
			state.designList = coll;
		},
		gotCartList(state, payload) {
			state.currency = payload.currency;
			state.cartInfo = payload.cart_info || {};
			let list = payload.cart_info ? payload.cart_info.products : [];
			let abc = []
			for (let i in list) {
				abc.push(list[i].qty)
				if (list[i].customized_product_item) {
					list[i].customized_product_item = JSON.parse(list[i].customized_product_item);
					if (list[i].customized_product_item.sku && list[i].customized_product_item.skuLanguages) {
						list[i].customized_product_item.sku = list[i].customized_product_item.skuLanguages[state.config.languageCode];
					}
				}
			}
			console.log('gotCartList', list)
			console.log("abc: ", abc);
			var sum = abc.reduce((x, y) => x + y);
			console.log(sum, '*************'); // 15
			state.sum = sum;
			state.cartList = list;
		},
		gotAddressList(state, payload) {
			console.log(state, payload)
			state.addressList = payload;
		},
		gotCatalogsearch(state, payload) {
			console.log(state, payload)
			state.keywords = payload;
		},
		gotCartLoading(state, payload) {
			console.log(state, payload)
			state.cartLoading = payload;
		},
		gotBreadcumb(state, payload) {
			console.log(state.goodsBreadcumb)
			state.breadcumbs = state.goodsBreadcumb;
		},
		gotGoodsBreadcumb(state, payload) {
			console.log(payload)
			state.breadcumbs = {};
			state.goodsBreadcumb = payload || {};
		}

	},
	actions: {
		getuserInfo({ commit, dispatch }) {
			$api.getUserInfo().then(res => {
				commit('gotuserInfo', res.data || {});
				localStorage.setItem('userInfo', res.data ? JSON.stringify(res.data) : '{}');
			}, err => {
				console.log(err)
				// if(err.code == 1100003) {
				// 	commit('exit');
				// }
			})
		},
		getHomeBase({ commit, dispatch }) {
			//获取页面公共数据
			$api.getHomeBase().then(res => {
				// console.log(res)
				commit('gotHomeBase', res.data);
				localStorage.setItem('homeBase', JSON.stringify(res.data));
			})
		},
		getAddressIndex({ commit, dispatch }) {
			//编辑地址初始化
			$api.editAddressIndex().then(res => {
				// console.log(res)
				commit('gotAddressIndex', res.data);
				localStorage.setItem('countries', JSON.stringify(res.data));
			})
		},
		getFavorite({ commit, dispatch }) {
			//获取心愿单列表
			$api.getProductfavoriteIndex().then(res => {
				commit('gotFavorite', res.data.productList)
			})
		},
		getDesignList({ commit, dispatch }) {
			//获取心愿单列表
			$api.getDesignList().then(res => {
				console.log(res)
				commit('gotDesignList', res.data)
			})
		},

		getCartList({ commit, dispatch }) {
			//购物车列表
			$api.getCartIndex().then(res => {
				console.log(res)
				commit('gotCartLoading', 2);
				commit('gotCartList', res.data);
			})
		},
		getAddressList({ commit, dispatch }) {
			//地址列表
			$api.getAddressIndex().then(res => {
				console.log(res)
				commit('gotAddressList', res.data.addressList)
			})
		},
		getCatalogsearch({ commit, dispatch }) {
			//获取推荐关键词
			$api.getCatalogsearch({ p: 1, numPerPage: '30', q: '戒指' }).then(res => {
				console.log(res)
				commit('gotCatalogsearch', res.data.keywords_recommend)
			})
		},
		gotLogin() {
			console.log(this)
			MessageBox({
				title: this.$t('message.Prompt'),
				message: this.$t('message.Logged'),
				showCancelButton: true,
				confirmButtonText: this.$t('message.CONFIRM'),
				cancelButtonText: this.$t('message.cancel'),
				type: 'warning'
			}).then(() => {
				router.push('/login');
			})
		}
	},
	getters: {},
	modules: {}
})
