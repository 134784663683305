import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $api from '@/utils/api'
import store from './store'
import VueI18n from 'vue-i18n'
import messages from '@/utils/i18n'
import ElementUI from 'element-ui';
import lang from '@/utils/elementLanguages'
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible'
import VueLazyLoad from 'vue-lazyload'
import Meta from 'vue-meta';

Vue.prototype.$api = $api;
Vue.config.silent = true
Vue.config.productionTip = false

Vue.use(VueLazyLoad, {
	attempt: 1,
	preLoad: 1,
	listenEvents: ['scroll'],
	loading: require('@/assets/images/loading.svg')
})
Vue.use(VueI18n)
Vue.use(ElementUI, {
	locale: lang[localStorage.getItem("lang") || 'en']
});

// 多语言
const i18n = new VueI18n({
	locale: localStorage.getItem("lang") || 'en',
	messages
})

//路由拦截
router.beforeEach((to, from, next) => {
	next()
})
Vue.use(router)
Vue.use(Meta);

// console.log=()=>{};

new Vue({
	i18n,
	router,
	store,
	Meta,
	render: h => h(App)
}).$mount('#app')
