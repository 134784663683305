<template>
	<!-- 设置语言 -->
	<div class="set">
		<el-popover placement="bottom" width="300" trigger="click">
			<div class="text1">{{$t('message.indexFN')}}</div>
			<div class="text2">{{$t('message.Countries')}}</div>
			<el-select v-model="lan.countriesCode" filterable >
				<el-option v-for="(item, index) in country" :key="item.country_code" :label="item.country_code" :value="item.country_symbol">
				</el-option>
			</el-select>
			<div class="text2">{{$t('message.language')}}</div>
			<el-select v-model="lan.languageCode">
				<el-option v-for="item in langList" :key="item.code" :label="item.languageName" :value="item.code">
				</el-option>
			</el-select>
			<div class="text2">{{$t('message.currency')}}</div>
			<el-select v-model="lan.currencyCode">
				<el-option v-for="item in currencyList" :key="item.code" :label="item.code + ' ' + item.symbol" :value="item.code">
				</el-option>
			</el-select>
			<div class="btn1 flexJA" @click="bindSave">{{$t('message.submit')}}</div>
			<el-button slot="reference">click 激活</el-button>
		</el-popover>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				lan: {
					countries: '',
					countriesCode: '',
					language: '',
					languageCode: '',
					currency: '',
					currencyCode: ''
				}
			}
		},
		computed: mapState([
			'config',
			'userInfo',
			'langList',
			'currencyList',
			'country'
		]),
		methods: {
			bindSave() {
				this.$emit('bindLanguage');
				localStorage.setItem("lang", this.config.languageCode);
				localStorage.setItem('language', JSON.stringify(this.config));
				location.href = location.href;
			}
		},
		mounted() {
			this.lan = this.config;
		}
	}
</script>

<style scoped lang="scss">
	.set {
		left: 0;
		top: 0;
		width: 100%;
		position: absolute;

		.el-button {
			width: 300px;
			opacity: 0;
		}
	}
	
	.el-select {
		width: 300px;
	}
	
	::v-deep .el-input__inner {
		border-radius: 0;
	}
	
	.el-select-dropdown__item {
		text-align: center;
	}
	
	.text1 {
		font-size: 16px;
		word-break: break-word;
		font-weight: 700;
		margin-top: 10px;
		color: #666666;
	}
	
	.text2 {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		margin: 15px 0 6px;
	}
	
	.btn1 {
		width: 300px;
		height: 40px;
		font-size: 16px;
		
		font-weight: 700;
		color: #FFFFFF;
		background: #3345CB;
		margin-top: 30px;
		margin-bottom: 15px;
	
		&:hover {
			opacity: 0.8;
		}
	}
	
	.btn2 {
		width: 300px;
		height: 40px;
		font-size: 18px;
		
		font-weight: 700;
		color: #666666;
		background: #fafafa;
		margin-top: 30px;
		margin-bottom: 15px;
		box-sizing: border-box;
		border: 1px solid #E4ECF1;
	
		&:hover {
			opacity: 0.5;
		}
	}
	
	.btn3 {
		width: 220px;
		height: 50px;
		font-size: 16px;
		
		font-weight: 400;
		color: #666666;
		margin-left: -10px;
		border-bottom: 1px solid #E4ECF1;
	
		&:hover {
			background: #3345cb;
			color: #ffffff;
		}
	
		&:nth-child(1) {
			margin-top: 20px;
		}
	
		&:nth-child(4) {
			border-bottom: none;
		}
	}
</style>
