<template>
	<div class="a" v-if="isShow">
		<div class="mask" @click="bindHide"></div>

		<div class="dialog">
			<!-- 头部菜单 -->
			<div class="menu flexJA">
				<div class="item" @click="mouseoverMenu(0)"><img src="@/assets/images/2-2.png"></div>
				<div class="item" @click="mouseoverMenu(1)"><img src="@/assets/images/3-2.png"></div>
				<div class="item flexJA" @click="mouseoverMenu(2)"><img src="@/assets/images/4-2.png"><span>({{favoriteList.length}})</span></div>
				<div class="item flexJA" @click="mouseoverMenu(3)"><img src="@/assets/images/5-2.png"><span>({{designList.length}})</span></div>
				<div class="item flexJA" @click="mouseoverMenu(4)"><img src="@/assets/images/6-2.png"><span>({{cartList.length}})</span></div>
			</div>
			<!-- 搜索 -->
			<div class="search" v-if="menu == 0">
				<div class="text">{{$t("message.QUERY")}}</div>
				<div class="input flexJA flexSb">
					<input type="text" :placeholder="$t('message.please')" v-model="search" @keyup.enter="bindSearch">
					<img src="@/assets/images/z29.png" @click="bindSearch">
				</div>
				<div class="del flexJA flexSb" v-if="recordsList.length">
					<div class="text">{{$t("message.QUERY")}} {{$t("message.Records")}}</div>
					<img src="@/assets/images/z30.png" @click="bindDelAll()">
				</div>
				<div class="record flexJA flexFs" v-if="recordsList.length">
					<div class="item flexJA" v-for="(item,index) in recordsList" @click="search = item, bindSearch()">{{item}}<i class="el-icon-close" @click="bindDel(index)"></i></div>
				</div>
				<div class="text" v-if="keywords">{{$t("message.Recommends")}}</div>
				<div class="recommend flexJA flexSb" v-if="keywords">
					<div class="left">
						<router-link class="item flexJA flexFs" v-for="(item, index) in keywords.title" :to="`/search?q=${item}`">{{item}}</router-link>
					</div>
					<div class="right cursor" @click="bindOpen(keywords.url)">
						<img :src="keywords.image">
						<div class="black"></div>
						<!-- <div class="text1">Design Your Own Engagement Ring</div>
						<div class="text2">in your way</div> -->
						<!-- <div class="btn flexJA">go to</div> -->
					</div>
				</div>
			</div>
			<!-- 清单 -->
			<div class="inventory" v-if="menu == 2">
				<div class="top flexJA flexSb">
					<div class="text">{{$t("message.Wishlist")}} ( {{favoriteList.length}} )</div>
					<router-link class="more flexJA flexSb" to="/inventory" v-if="favoriteList.length">
						<div class="">{{$t('message.Viewall')}}</div><img src="@/assets/images/z32.png">
					</router-link>
					<div class="more flexJA flexSb" v-if="!favoriteList.length"></div>
				</div>
				<!-- 空 -->
				<div class="empty flexJA flexDc" v-if="!favoriteList.length">
					<div>{{$t("message.listEmpty")}}</div>
					<img src="@/assets/images/z33.png">
				</div>
				<!-- 有数据 -->
				<div class="list" v-if="favoriteList.length">
					<div class="item flexJA flexFs" v-for="(item, index) in favoriteList">
						<div class="bg flexJA"><img :src="item.imgUrl"></div>
						<div class="right">
							<div class="name cursor" @click="bindNav(item.product_id, item)">{{item.name}}</div>
							<div class="price" v-if="item.price_info">
								{{item.price_info.price.code}}{{item.price_info.price.symbol}}
								{{item.price_info.price.value}}</div>
							<div class="bar flexJA flexFs" @click="addCart(item.product_id)" v-if="!item.is_customized">
								<div>{{$t("message.addCart")}}</div><img class="icon" src="@/assets/images/z32.png">
							</div>
							<div class="bar flexJA flexFs" @click="delFavorite(item.id)">
								<div>{{$t("message.Remove")}}</div><img class="icon" src="@/assets/images/z32.png">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 设计 -->
			<div class="design" v-if="menu == 3">
				<div class="top flexJA flexSb">
					<div class="text">{{$t('message.MyDesign')}} ( {{designList.length}} )</div>
					<div class="more flexJA flexSb">
						<router-link class="flexJA" to="/designVersion" v-if="designList.length">
							<div>{{$t('message.Viewall')}}</div> <img src="@/assets/images/z32.png">
						</router-link>
					</div>
				</div>
				<div class="empty" v-if="!designList.length">
					<div class="text">{{$t('message.designYet')}}</div>
					<div class="text">{{$t('message.Starthere')}}</div>
					<!-- <div class="btn flexJA cursor">{{$t('message.GoStudio')}}</div> -->
				</div>
				<div class="list" v-if="designList.length">
					<div class="item flexJA flexFs" v-for="(item, index) in designList">
						<div class="bg" ><img :src="item.image"></div>
						<div class="right">
							<router-link class="name" :to="`/variableDetails?id=${item.product_id}`">{{item.product.name}}</router-link>
							<div class="text" v-if="item.info.diamonds">
								<span>Diamonds：</span>
								<span style="margin-right: 5px;">{{item.info.diamonds.weight}}gt</span>
								<span style="margin-right: 5px;">color: {{item.info.diamonds.color}}</span>
								<span style="margin-right: 5px;">clarity: {{item.info.diamonds.clarity}}</span>
								<span style="margin-right: 5px;">cut: {{item.info.diamonds.cut}}</span>
								<span style="margin-right: 5px;">symmetry: {{item.info.diamonds.symmetry}}</span>
								<span style="margin-right: 5px;">polish: {{item.info.diamonds.polish}}</span>
								<span style="margin-right: 5px;">fluor: {{item.info.diamonds.fluor}}</span>
							</div>
							<div class="text" v-if="item.info.size">Size：{{item.info.size}}</div>
							<div class="text" v-if="item.info.costPrice > 0">Price：{{designData.current_currency.code}}{{designData.current_currency.symbol}} {{item.info.costPrice}}</div>
							<div class="text" v-if="item.info.costPrice == 0">Price：NULL</div>
							<div class="bar flexJA flexFs" @click="bindCheckOut(item.info.o)"><div>{{$t('message.CheckOut')}}</div><img class="icon" src="@/assets/images/z32.png"></div>
							<div class="text cursor" @click="delDesign(item.id)">{{$t("message.Remove")}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 购物车 -->
			<div class="shop flexJA flexDc" v-if="menu == 4">
				<div class="top flexJA flexSb">
					<div class="text">{{$t('message.ShoppingCart')}} ( {{cartList.length}} )</div>
					<router-link class="more flexJA flexSb" to="/shoppingCart" v-if="cartList.length">
						<div class="">{{$t('message.Viewall')}}</div><img src="@/assets/images/z32.png">
					</router-link>
					<div class="more flexJA flexSb" v-if="!cartList.length"></div>
				</div>
				<!-- 空 -->
				<div class="empty flexJA flexDc" v-if="!cartList.length">
					<div>{{$t('message.cartEmpty')}}</div>
					<img src="@/assets/images/z35.png">
				</div>
				<div class="list" style="flex: 1;" v-if="cartList.length">
					<div class="item flexJA flexFs" v-for="(item, index) in cartList">
						<div class="left">
							<el-checkbox :value="item.active == 1" @change="bindRadio(item.item_id, item.active == 1 ? 2 : 1)"></el-checkbox>
							<div class="bg" >
								<img :src="item.img_url">
								<div class="rate" v-if="item.discountrate">{{item.discountrate}}%off</div>
							</div>
						</div>
						<div class="right">
							<div class="name cursor" @click="bindNav(item.product_id, item)">{{item.name}}</div>
							<div class="text" v-for="(items, j) in item.custom_option_info"  v-if="item.is_customized != 1">{{j}}：{{items}}</div>
							<div class="text" v-if="item.option_sku && item.option_sku.size">size：{{item.option_sku.size}}</div>
							<div class="text" v-if="item.customized_product_item">
								<div class="text flexJA flexFs" v-for="(item, index) in item.customized_product_item.sku">{{item}}</div>
								<div v-if="item.customized_product_item.diamonds">
									<span>Diamond {{item.customized_product_item.diamonds.goods_name}}：</span>
									<span style="margin-right: 5px;">{{item.customized_product_item.diamonds.weight}}gt</span>
									<span style="margin-right: 5px;">color: {{item.customized_product_item.diamonds.color}}</span>
									<span style="margin-right: 5px;">clarity: {{item.customized_product_item.diamonds.clarity}}</span>
									<span style="margin-right: 5px;">cut: {{item.customized_product_item.diamonds.cut}}</span>
									<span style="margin-right: 5px;">symmetry: {{item.customized_product_item.diamonds.symmetry}}</span>
									<span style="margin-right: 5px;">polish: {{item.customized_product_item.diamonds.polish}}</span>
									<span style="margin-right: 5px;">fluor: {{item.customized_product_item.diamonds.fluor}}</span>
								</div>
							</div>
							<div class="text">
								<span>{{currency.code}}</span>
								<span style="text-decoration:line-through #f00 2px;margin: 0 5px;" v-if="item.discountrate">{{currency.symbol}}{{item.product_original_price}}</span>
								<span :style="item.discountrate ? 'color:#f00' : ''">{{currency.symbol}}{{item.product_price}}</span>
								<span style="margin-left: 10px;">x {{item.qty}}</span>
							</div>
							<div class="bar flexJA flexFs" @click="cartUpdateinfo(item.item_id)" v-if="item.status == 1">
								<div>{{$t("message.Remove")}}</div><img class="icon" src="@/assets/images/z32.png">
							</div>
						</div>
						<div class="failure flexJA" v-if="item.status == 2">
							<span>{{$t("message.failure")}}</span>
							<div class="cursor flexJA" @click="cartUpdateinfo(item.item_id)">{{$t("message.Remove")}}</div>
						</div>
					</div>
				</div>
				<div class="bottom flexJA flexSb" v-if="cartList.length">
					<div class="all flexJA flexDc">
						<el-checkbox :value="checked" @change="bindAll"></el-checkbox>
						<div>{{$t('message.ChooseAll')}}</div>
					</div>
					<div class="box flexJA flexSb">
						<div class="flexJA flexFs">
							<span>{{$t('message.Select')}} {{cartInfo.items_count}}, {{$t('message.TOTAL')}}：{{currency.code}}</span>
							<span v-if="cartInfo.product_total == cartInfo.product_original_total">{{currency.symbol}} {{cartInfo.product_total}}</span>
							<div class="flexJA" style="flex-direction: column; position: relative;" v-if="cartInfo.product_total != cartInfo.product_original_total">
								<span style="text-decoration:line-through #f00 2px;">{{currency.symbol}} {{cartInfo.product_original_total}}</span>
								<span >{{currency.symbol}} {{cartInfo.product_total}}</span>
							</div>
						</div>
						<div class="btn flexJA" :class="{disabled: cartInfo.items_count == 0}" @click="checkOut">{{$t('message.CheckOut')}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- </el-drawer> -->
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import { Loading, Message } from 'element-ui';

	export default {
		props: ['drawer', 'isShow', 'menus'],
		data() {
			return {
				recordsList: [],
				checked: false,
				menu: '',
				search: ''
			}
		},
		computed: {
			...mapState([
				'token',
				'homeBase',
				'bottomNav',
				'cartList',
				'cartInfo',
				'currency',
				'designData',
				'designList',
				'favoriteList',
				'keywords'
			])
		},
		watch: {
			cartList(to, form) {
				let checked = true;
				for (let i in to) {
					if (to[i].active != 1) checked = false;
				}
				this.checked = checked;
			},
			'$route'() {
				this.getRecordsList();
			}
		},
		mounted() {
			this.menu = this.menus;
			this.getRecordsList();
			this.allInit();
		},
		methods: {
			bindOpen(u) {
				window.open(u)
			},
			bindSearch() {
				if(this.search) {
					let history = this.recordsList || [];
					history.unshift(this.search);
					console.log(history)
					localStorage.setItem('history', JSON.stringify(history));
					this.$router.push(`/search?q=${this.search}`);
					this.bindHide();
				}
			},
			getRecordsList() {
				this.recordsList = localStorage.getItem('history') ? JSON.parse(localStorage.getItem('history')) : '';
				this.recordsList = Array.from(new Set(this.recordsList));
			},
			allInit() {
				let checked = true;
				for(let i in this.cartList) {
					if(this.cartList[i].active != 1) checked = false;
				}
				this.checked = checked;
			},
			bindNav(id, item) {
				if(item.is_customized == 1) {
					if(item.customized_type == 1) {
						this.$router.push(`/designDetails?id=${id}`);
					} else if(item.customized_type == 2) {
						this.$router.push(`/variableDetails?id=${id}`);
					} else if(item.customized_type == 4) {
						this.$router.push(`/commodityDetails?id=${id}`);
					}
					
				}else {
					this.$router.push(`/details?id=${id}`);
				}
			},
			bindDel(e) {
				let arr = this.recordsList
				arr.splice(e, 1)
				this.recordsList = arr;
				localStorage.setItem('history', JSON.stringify(arr));
			},
			bindDelAll() {
				this.recordsList = [];
				localStorage.setItem('history', JSON.stringify(this.recordsList));
			},
			bindAll() {
				// 购物车全选
				this.$api.selectCartAll({
					checked: this.checked ? 2 : 1
				}).then(res => {
					this.$store.dispatch('getCartList');
				})
			},
			bindRadio(item_id, checked) {
				// 购物车单选
				console.log(item_id, checked)
				this.$api.selectCartOne({
					item_id,
					checked
				}).then(res => {
					this.$store.dispatch('getCartList');
				})
			},
			addCart(product_id) {
				//加入购物车
				this.$api.addCart({
					product_id: product_id,
					qty: 1
				}).then(res => {
					Message({
						type: 'success',
						message: 'success'
					});
					this.$store.dispatch('getCartList');
				})
			},
			checkOut() {
				// 点击结账
				this.$router.push('/paymentInfo');
			},
			// 鼠标上移切换菜单
			mouseoverMenu(menu) {
				if (menu != 1) {
					this.menu = menu
				} else {
					if(this.token && this.$route.fullPath != "/myOrder/message?type=1") {
						this.$router.push(`/myOrder/message?type=1`);
					}else if(!this.token){
						this.$router.push(`/login`);
					}
				}
			},
			bindHide() {
				this.$emit('MenuBarHide', false)
			},
			delFavorite(id) {
				//删除心愿单
				this.$api.productFavorite({
					product_id: id,
					type: 'del'
				}).then(res => {
					this.$store.dispatch('getFavorite');
				})
			},
			delDesign(id) {
				//删除设计板
				this.$api.delDesign({id: id}).then(res => {
					this.$store.dispatch('getDesignList');
				})
			},
			cartUpdateinfo(id) {
				//删除购物车
				this.$api.updateCart({
					item_id: id,
					up_type: 'remove'
				}).then(res => {
					this.$store.dispatch('getCartList');
				})
			},
			bindCheckOut(o) {
				console.log(o)
				this.$api.addCart(o).then(res => {
					this.$store.dispatch('getCartList');
					this.$router.push('/shoppingCart');
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.a {
		overflow-x: hidden;
	}

	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #333333;
		opacity: 0.5;
		z-index: 98;
	}

	.dialog {
		position: fixed;
		top: 0;
		right: 0;
		width: 2.6rem;
		height: 4.2rem;
		background: #FBFBFB;
		z-index: 99;
		box-sizing: border-box;
		overflow: scroll;

		.menu {
			width: 100%;
			height: 0.416667rem;
			background: #3345CB;
			z-index: 99;

			.item {
				margin-left: 0.130208rem;
				align-items: flex-end;
				

				color: #ffffff;
				cursor: pointer;
			}

			span {
				margin-left: 3px;
			}

			img {
				height: 0.125rem;
			}
		}

		.top {
			padding: 0.208333rem 0 0.104167rem;
			border-bottom: 1px solid #E4ECF1;

			.text {
				cursor: pointer;
				font-size: 0.083333rem;
				color: #333333;
				border-bottom: 1px solid #333333;
			}

			.more {
				cursor: pointer;
				


				color: #666666;

				img {
					width: 0.03125rem;
					margin-left: 10px;
				}

				&::before {
					content: '';
					width: 1px;
					height: 30px;
					background: #E4ECF1;
					margin-right: 0.104167rem;
				}
			}
		}
	}

	.search {
		margin: 0.104167rem 0.104167rem 0.208333rem;

		.text {
			font-size: 0.083333rem;


			color: #333333;
		}

		.input {
			width: 2.135417rem;
			height: 0.229167rem;
			border: 2px solid #E4ECF1;
			border-radius: 4px;
			margin-top: 0.104167rem;
			padding: 0 0.057292rem;
			box-sizing: border-box;
			margin-bottom: 12px;

			input {
				width: 1.5625rem;
			}

			img {
				width: 0.125rem;
				cursor: pointer;
			}
		}

		.del {
			margin-top: 42px;
			padding-bottom: 10px;
			border-bottom: 1px solid #E4ECF1;
			margin-bottom: 12px;

			img {
				cursor: pointer;
				width: 14px;
				height: 16px;
			}
		}

		.record {
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: 10px;

			.item {
				cursor: pointer;
				


				color: #666666;
				border-right: 1px solid #666666;
				margin: 0.0625rem 0;
				margin-right: 0.104167rem;

				.el-icon-close {
					margin: 0 0.0625rem 0 0.052083rem;
				}

				&:hover {
					color: #3345CB;
				}
			}
		}

		.recommend {
			align-items: start;
			border-top: 1px solid #E4ECF1;
			padding-top: 0.104167rem;
			margin-top: 10px;

			.left {
				.item {
					


					color: #666666;
					margin-bottom: 0.104167rem;
					border-bottom: 1px solid #ffffff;
					box-sizing: border-box;

					&:hover {
						color: #3345CB;
						border-bottom: 1px solid #3345CB;
					}
				}
			}

			.right {
				position: relative;
				width: 0.833333rem;
				height: 1.1rem;
				overflow: hidden;

				img {
					width: 0.833333rem;
				}

				.black {
					position: absolute;
					top: 0;
					width: 0.833333rem;
					background: #333333;
					opacity: 0.5;
				}

				.text1 {
					position: absolute;
					top: 0.208333rem;
					left: 0.114583rem;
					


					color: #FEFEFE;
				}

				.text2 {
					position: absolute;
					top: 104px;
					left: 50%;
					transform: translate(-50%, 0);
					

					color: #FEFEFE;
				}

				.btn {
					cursor: pointer;
					position: absolute;
					bottom: 20px;
					left: 50%;
					transform: translate(-50%, 0);
					width: 0.385417rem;
					height: 0.15625rem;
					

					color: #FEFEFF;
					background: #3345CB;
					border-radius: 8px;

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}

	.inventory {

		padding: 0 0.104167rem;

		.empty {
			margin-top: 160px;

			div {
				font-size: 0.145833rem;


				color: #666666;
				margin-bottom: 0.416667rem;
			}

			img {
				width: 0.833333rem;
			}
		}

		.list {
			.item {
				align-items: flex-start;
				border-bottom: 1px solid #E4ECF1;
				margin-top: 0.104167rem;

				.bg {
					width: 0.5625rem;
					height: 0.5625rem;
					align-items: flex-start;
					
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.right {
					flex: 1;
					color: #666666;
					margin-left: 0.104333rem;

					.name {
						color: #333333;
						margin-bottom: 0.130208rem;
					}

					.price {
						color: #666666;
						margin-bottom: 0.104167rem;
					}

					.bar {
						cursor: pointer;
						margin-bottom: 0.104167rem;

						.icon {
							width: 0.03125rem;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	.design {
		padding: 0 0.104167rem;

		.empty {
			text-align: center;
			margin-top: 0.677083rem;

			.text {
				font-size: 0.145833rem;
				color: #666666;
				margin: 0.15625rem 0.250417rem 0;
			}

			.btn {
				width: 1.5625rem;
				height: 0.270833rem;
				font-size: 0.083333rem;
				color: #3345CB;
				border: 2px solid #3345CB;
				margin: 0.208333rem auto 0;
			}
		}

		.list {
			.item {
				align-items: start;
				border-bottom: 1px solid #E4ECF1;
				margin-top: 0.104167rem;
				padding-bottom: 5px;

				.bg {
					width: 0.5625rem;
					height: 0.5625rem;
					
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.right {
					
					color: #666666;
					margin-left: 0.208333rem;

					.text {
						margin-bottom: 10px;
					}

					.name {
						color: #333333;
						margin-bottom: 10px;
						text-align: left;
					}

					.bar {
						cursor: pointer;
						margin-bottom: 0.08625rem;

						.icon {
							width: 0.03125rem;
							margin-left: 5px;
						}
					}
				}
			}
		}
	}

	.shop {
		height: calc(100% - 0.416667rem);
		overflow: hidden;

		.top {
			width: calc(100% - 0.208333rem);
			padding: 0.208333rem 0.104167rem 0.104167rem;

			.text {
				cursor: auto;
			}
		}

		.empty {
			flex: 1;
			width: calc(100% - 0.208333rem);
			padding: 0 0.104167rem;
			margin-top: 0.104167rem;

			div {
				font-size: 0.145833rem;
				color: #666666;
				margin-bottom: 0.416667rem;
			}

			img {
				width: 0.833333rem;
			}
		}

		.list {
			flex: 1;
			width: 100%;
			overflow: hidden;
			overflow-y: auto;

			.item {
				align-items: start;
				border-bottom: 1px solid #E4ECF1;
				position: relative;
				padding: 0.104167rem;

				.left {
					.bg {
						position: relative;
						width: 0.5625rem;
						margin-top: 0.052083rem;
						
						img {
							width: 100%;
						}
						
						.rate {
							top: 0;
							left: 0;
							padding: 4px 8px;
							background: #f00;
							color: #fff;
							font-size: 12px;
							position: absolute;
							opacity: 0.9;
						}
					}
				}

				.right {
					
					color: #666666;
					margin-left: 0.208333rem;

					.name {
						color: #333333;
					}

					.text {
						margin: 0.078125rem 0;
					}

					.bar {
						cursor: pointer;
						margin: 0.052083rem 0 0;

						.icon {
							width: 0.03125rem;
							margin-left: 10px;
						}
					}
				}
			}
			
			.failure {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
				position: absolute;
				background: rgba(0, 0, 0, 0.3);
				
				span {
					color: #fff;
					font-size: 0.208333rem;
					opacity: 0.6;
				}
				
				div {
					display: none;
					width: 80px;
					height: 80px;
					background: #3345CB;
					color: #fff;
					border-radius: 100%;
					font-size: 0.135417rem;
				}
				
				&:hover {
					span {
						display: none;
					}
					
					div {
						display: flex;
					}
				}
			}
		}

		.bottom {
			box-shadow: 0 0 5px #E4ECF1;
			width: calc(100% - 0.208333rem);
			padding: 0 0.104167rem;
			height: 0.416667rem;
			background: #fbfbfb;

			.all {
				margin-right: 0.052083rem;
				text-align: center;

				div {
					font-size: 0.0625rem;
					color: #666666;
					margin-top: 0.0417rem;
				}
			}

			.box {
				flex: 1;
				height: 0.25rem;
				background: #3345CB;
				padding: 0 0.052083rem 0 0.052083rem;
				box-sizing: border-box;

				div {
					font-size: 12px;
					color: #FFFFFF;
				}

				.btn {
					font-size: 12px;
					width: 0.46875rem;
					height: 0.15625rem;
					
					color: #3345CB;
					background: #FBFBFB;
					border-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #3345cb;
		border-color: #3345cb;
	}

	::v-deep .el-checkbox__inner {
		border: 1px solid #666666;
		border-radius: 0;
	}
</style>
