<template>
	<div class="content_container">
		<div class="container">
			<div class="subscribe">
				<div class="line"></div>
				<div class="from">
					<div class="inp flexJA">
						<span>{{ $t("message.subscribeFA") }}</span>
						<input type="email" :placeholder="$t('message.subscribeFB')" v-model="email" />
						<button :class="{ disabled: !email }" @click="bindSubscribe">
							{{ $t("message.subscribeFC") }}
						</button>
					</div>
				</div>
				<div class="line"></div>
			</div>
			<div class="top">
				<div class="item" v-for="(item, index) in bottomNav">
					<div class="title">{{ item.name }}</div>
					<div class="list">
						<div class="cursor" v-for="(items, index) in item.sub" :key="index"
							@click="bindNav(`/article?tid=${item.id}&aid=${items.id}`, items)">
							{{ items.name }}
						</div>
						<!-- <div class="cursor" v-for="(items, index) in item.sub" :key="index" @click="bindNav(`/article/${item.id}/${items.id}/${item.name}/${items.name}`,items)" >{{items.name}}</div> -->
					</div>
				</div>
				<div class="item">
					<div class="title">{{ $t("message.contact_us") }}</div>
					<div class="list">
						<div class="flexJA flexFs" @click="bindCopy(homeBase.email)" v-if="homeBase.email">
							<img src="@/assets/images/14.png" />
							<div>{{ homeBase.email }}</div>
						</div>
						<!-- <div class="flexJA flexFs" @click="LiveChatMax">
							<img src="@/assets/images/15.png" />
							<div>{{ $t("message.OnlineConsultan") }}</div>
						</div> -->
						<div class="flexJA flexFs" @click="whatapp">
							<img src="@/assets/images/whatsapp.png" />
							<div>{{ $t("message.whatsApp") }}</div>
						</div>
					</div>
				</div>
				<div>
					<div class="item" v-for="(item, index) in homeBase.bottom_pictures" style="margin-bottom: 20px">
						<!-- <div class="title" style="padding-bottom: 5px;">{{$t('message.WEACCEPT')}}</div> -->
						<div class="title" style="padding-bottom: 5px">{{ index }}</div>
						<div class="imglist flexJA flexSb">
							<div v-for="(items, j) in item" class="flexJA li" :class="{ cursor: items.url }"
								@click="bindOpen(items.url)">
								<img v-lazy="items.image" />
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="footer">
			<div class="verified flexJA">
				<!-- <div>{{homeBase.verified.title}}</div>v-if="homeBase.verified"
					<img v-for="(item, index) in homeBase.verified.info" :class="{cursor: item.url}" @click="bindOpen(item.url)" v-lazy="item.image"> -->
				<div>Powered By Trueview Meta Technology</div>
				<div class="tips">Copyright ©2022-2023 Bless Jewellery </div>
				<div class="tips_end"><span>Bless Jewellery Is Verified By</span><img src="@/assets/images/z97.png" /></div>

			</div>
		</div>
		<!-- <div class="tips">{{homeBase.copy_right}}</div> -->
	</div>
</template>

<script>
import { mapState } from "vuex";
import { Notification } from "element-ui";
export default {
	name: "Footer",
	props: {
		msg: String,
	},
	computed: mapState(["homeBase", "bottomNav", "TITLE"]),
	data() {
		return {
			email: "",
		};
	},
	methods: {
		bindSubscribe() {
			this.$api.Subscribe({ email: this.email }).then((res) => {
				this.email = "";
				Notification({
					type: "success",
					message: this.$t("message.subscribeFD"),
				});
			});
		},
		// LiveChatMax() {
		// 	// 最大化了聊天小部件
		// 	LiveChatWidget.call("maximize");

		// },
		//吊起whatsApp
		whatapp() {
			window.location.href = 'https://api.whatsapp.com/send?phone=+94870011&text=Hello';
		},
		bindCopy(e) {
			const input = document.createElement("input");
			document.body.appendChild(input);
			input.setAttribute("value", e);
			input.select();
			if (document.execCommand("copy")) {
				document.execCommand("copy");
				console.log("复制成功！", "success");
			}
			document.body.removeChild(input);
		},
		bindNav(url, item) {
			console.log(url);
			url = encodeURI(url);
			if (url == this.$route.fullPath) return;
			this.$store.commit("gotMetaInfo", {
				title: `${this.TITLE} ${item.title}`,
				Keywords: item.meta_keywords || this.TITLE,
				description: item.meta_description || this.TITLE,
			});
			this.$router.push(url);
		},
		bindOpen(url) {
			url && window.open(url);
		},
	},
};
</script>

<style scoped lang="scss">
.subscribe {
	background: #fff;
	padding: 10px 0;

	.line {
		height: 2px;
		background: #eee;
	}

	.from {
		width: 60%;
		margin: 30px auto;
		text-align: center;
	}

	.inp {
		// margin-bottom: 20px;

		span {
			font-size: 0.15rem;
			font-weight: bold;
			margin-right: 30px;
		}

		input {
			height: 48px;
			padding: 0 20px;
			min-width: 240px;
			border: 1px solid #000;
		}

		button {
			height: 50px;
			padding: 0 30px;
			font-size: 0.125rem;
			border: 1px solid #000;
			border-left: 0;
			background: #fff;
			cursor: pointer;
			font-weight: bold;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.content_container {
	width: 100%;

	// background: #f4e5e5;
	// padding: 0 0 14px;
}

.container {
	width: 100%;
	// z-index: 10;
	// background: #D8EAF4;
	background: #f4e5e5;
	// position: relative;
	padding: 0 0 14px;
}

.top {
	display: flex;
	max-width: 1400px;
	margin: 0 auto;
	justify-content: space-between;
	padding: 0.208333rem 1rem 23px;
}

.title {
	font-size: 14px;
	font-weight: bold;
	color: #000000;
	margin-bottom: 0.104167rem;
}

.list {
	display: flex;
	flex-direction: column;

	a,
	div {
		font-size: 14px;
		color: #000000;
		padding: 5px 0;

		&:hover {
			opacity: 0.5;
		}

		img {
			width: 24px;
			height: 18px;
			margin-right: 9px;
		}
	}
}

.imglist {
	flex-wrap: wrap;
	width: 1.8rem;
	justify-content: flex-start;

	.li {
		width: 0.3rem;
		height: 0.3rem;
		// background-color: #fff;
		border-radius: 4px;
		margin-bottom: 16px;
		margin-right: calc(0.3rem / 4);

		img {
			width: 0.3rem;
		}

		&:hover {
			opacity: 0.7;
		}

		&:nth-child(5n) {
			margin-right: 0;
		}
	}
}

.footer {
	width: 100%;
	background: #f6f6f6;
	height: 30px;
	line-height: 30px;
}

.verified {
	color: #000000;
	// padding-top: 18px;
	display: flex;
	width: 50%;

	margin: 0 auto;
	justify-content: space-around;

	div {
		font-size: 13px;

		// font-weight: bold;
	}

	img {
		height: 30px;
		margin-left: -70px;
		width: 55px;
		height: 22px;
	}
}

.tips {
	font-size: 14px;
	// font-weight: 400;
	color: #000000;
	text-align: center;
	// padding-top: 18px;
	// border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.tips_end {
	width: 240px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
