<template>
	<header class="header">
		<div class="top flexJA flexSb">
			<div class="left flexJA">
				<img src="@/assets/images/1.png" />
				<div>
					<span>{{ config.countries }}</span><span>{{ config.language }} / {{ config.currency }}</span>
				</div>
				<img src="@/assets/images/whatsapp.png" @click="whatapp" style="z-index:999" />

				<HeaderLanguage @bindLanguage="bindLanguage"></HeaderLanguage>
			</div>
			<router-link class="item flexJA uppercase" to="/"><img :src="homeBase.web_logo" class="logo" /></router-link>

			<div class="right flexJA">
				<div class="item" @click="(MenuBarShow = true), (menu = 0)">
					<img src="@/assets/images/2.png" />
				</div>
				<div class="item">
					<img src="@/assets/images/3.png" />
					<HeaderLogin></HeaderLogin>
				</div>
				<div class="item flexJA" @click="(MenuBarShow = true), (menu = 2)">
					<img src="@/assets/images/4.png" /><span :class="{ are: favoriteList.length }">({{ favoriteList.length
					}})</span>
				</div>
				<div class="item flexJA" @click="(MenuBarShow = true), (menu = 3)">
					<img src="@/assets/images/5.png" /><span :class="{ are: designList.length }">({{ designList.length
					}})</span>
				</div>
				<div class="item flexJA" @click="(MenuBarShow = true), (menu = 4)">
					<img src="@/assets/images/6.png" /><span :class="{ are: cartList.length }">({{ sum
					}})</span>
				</div>
			</div>
		</div>
		<nav class="flexJA flexSb">
			<div class="list flexJA" v-for="(item, index) in topNav" :class="{ notHoveer: router }">
				<div class="item flexJA uppercase" :class="{ active: `/goods?id=${item.id}` == $route.fullPath }">
					<div class="title cursor" v-if="item.child != null">{{ item.name }}</div>
					<div class="title cursor" v-if="item.child == null">
						<router-link class="item flexJA uppercase" :to="item.url">
							{{ item.name }}
						</router-link>
					</div>
					<!-- <div class="title cursor" @click="bindNav(`/goods?id=${item.id}`, item)" >{{item.name}}</div> -->
					<div v-if="item.child">
						<Menus type="studio" :child="item.child" :data="item"></Menus>
					</div>
				</div>
			</div>
			<!-- <div class="list flexJA" :class="{notHoveer: router}">
				<router-link class="item flexJA uppercase" to="/appointment/inquire" :class="{active: `/appointment/inquire` == $route.fullPath}">
					{{$t("message.FindAStore")}}
				</router-link>
			</div> -->
		</nav>
		<MenuBar :drawer="drawer" :isShow="MenuBarShow" :menus="menu" @MenuBarHide="MenuBarShow = false" v-if="MenuBarShow">
		</MenuBar>
	</header>
</template>

<script>
import { mapState } from "vuex";
import Menus from "@/components/menus";
import MenuBar from "@/components/MenuBar";
import HeaderLogin from "@/components/HeaderLogin";
import HeaderLanguage from "@/components/HeaderLanguage";
export default {
	props: {},
	data() {
		return {
			router: false,
			drawer: false,
			MenuBarShow: false,
		};
	},
	components: {
		Menus,
		MenuBar,
		HeaderLogin,
		HeaderLanguage,
	},
	computed: mapState([
		"topNav",
		"userInfo",
		"homeBase",
		"config",
		"cartList",
		"designList",
		"favoriteList",
		"sum"
	]),
	watch: {
		$route(to, from) {
			this.MenuBarShow = false;
			this.router = true;

			setTimeout((e) => {
				this.router = false;
			}, 100);
		},
		MenuBarShow() {
			// this.$emit('bindHeight', this.MenuBarShow);
		},
	},
	mounted() { },
	methods: {
		//吊起whatsApp
		whatapp() {
			window.location.href =
				"https://api.whatsapp.com/send?phone=+94870011&text=Hello";
		},
		bindNav(url, item) {
			if (url == this.$route.fullPath) return;
			this.$store.commit("gotMetaInfo", {
				title: `${this.TITLE} ${item.title}`,
				Keywords: item.meta_keywords || this.TITLE,
				description: item.meta_description || this.TITLE,
			});
			// this.$router.replace(url);
			this.$router.push(url);
		},
		bindLanguage() {
			this.$emit("bindLanguage");
		},
	},
};
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	max-width: 1920px;
	padding: 0 calc((100% - 1920px) / 2);
	margin: 0 auto;
	top: 0px;
	left: 0px;
	position: relative;
	z-index: 10;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

	.top {
		position: relative;
		padding: 21px 80px;
		height: 0.46875rem;
		align-items: flex-start;

		img {
			height: 0.125rem;
		}

		.logo {
			top: 21px;
			left: 50%;
			transform: translateX(-50%);
			height: 0.46875rem;
			position: absolute;
		}
	}

	.left {
		cursor: pointer;
		padding-top: 7px;
		position: relative;

		div {
			margin-left: 17px;
			font-size: 0.083333rem;
			font-weight: 400;
			color: #999999;

			span {
				margin-right: 14px;
			}
		}

		&:hover {
			.set {
				display: block;
			}
		}
	}

	.right {
		padding-top: 7px;

		.item {
			margin-left: 25px;
			align-items: flex-end;
			font-size: 14px;
			font-weight: 400;
			color: #999999;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		span {
			margin-left: 3px;
		}

		.are {
			color: #0017c0;
		}
	}

	nav {
		max-width: 1920px;
		margin: 0 auto;
		padding: 0 1.354167rem;
		position: relative;

		.item {
			height: 0.338542rem;
			max-width: 230px;
			font-size: 18px;
			font-weight: bold;
			color: #666666;
			text-align: center;
			border-bottom: 4px solid transparent;

			.title {
				font-size: 18px;
				font-weight: bold;
				color: #666666;
			}

			&:hover {
				color: #3345cb;
				border-bottom: 4px solid #3345cb;

				.title {
					color: #3345cb;
				}

				.menu {
					z-index: 100;
					padding: 0.208333rem 0;
					height: 2.677083rem;
					box-shadow: 0 2px 4px #ccc;
				}
			}
		}

		.notHoveer {
			.menu {
				height: 0 !important;
				padding: 0 !important;
				box-shadow: 0 0 0;
			}
		}

		.active {
			color: #3345cb;
			border-bottom: 4px solid #3345cb;
		}
	}
}
</style>
