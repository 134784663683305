// import messagesDe from './languages/de_DE.js'
import messagesEn from './languages/en_US.js'
// import messagesEs from './languages/es_ES.js'
// import messagesFr from './languages/fr_FR.js'
// import messagesIt from './languages/it_IT.js'
// import messagesNl from './languages/nl_NL.js'
// import messagesPt from './languages/pt_PT.js'
// import messagesRu from './languages/ru_RU.js'
import messagesTc from './languages/tc.js'
import messagesZh from './languages/zh_CN.js'
const messages = {
	en: {
		message: messagesEn
	},
	tc: {
		message: messagesTc
	},
	zh: {
		message: messagesZh
	}
}

export default messages
