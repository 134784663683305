<template>
	<div class="dialogVisible flexJA flexDc" v-if="dialogVisible">
		<el-dialog :visible.sync="dialogVisible" width="7.2917rem" top="0">
	        <div class="title flexJA">{{$t('message.dialogVisibleFA')}}</div>
		    <div class="list flexJA flexSb">
		    	<div class="item">
		            <img src="@/assets/images/z24.png">
		    		<div class="text">{{$t('message.dialogVisibleFB')}}</div>
		    	</div>
		    	<div class="item">
		            <img src="@/assets/images/z25.png">
		    		<div class="text">{{$t('message.dialogVisibleFC')}}</div>
		    	</div>
		    	<div class="item">
		            <img src="@/assets/images/z26.png">
		    		<div class="text">{{$t('message.dialogVisibleFD')}}</div>
		    	</div>
		    </div>
		    <div class="form">
		    	<div class="text">
		    		<span>{{$t('message.dialogVisibleFE')}}</span>
		    		<span>{{$t('message.dialogVisibleFF')}}</span>
		    	</div>
		    	<div class="box flexJA flexFs">
		    		<el-input  class="num" v-model="measuredinput" placeholder="Please enter" type="number" :maxlength="3"></el-input>
					<select v-model="measuredvalue">
						<option  v-for="(item, index) in options" :value="item.value">{{item.label}}</option>
					</select>
		    		<div class="btn flexJA" :class="{disabled: !measuredinput}" @click="START">{{$t('message.dialogVisibleFJ')}}</div>
		    	</div>
		    	<div class="text1">{{$t('message.dialogVisibleFG')}} {{size}}</div>
		    	<!-- <div class="text2">{{$t('message.dialogVisibleFH')}}</div> -->
		    	<!-- <div class="text3 flexJA cursor" :class="{disabled: !size || size == 'NULL'}" @click="bindSave">{{$t('message.dialogVisibleFI')}}</div> -->
		    </div>
	    </el-dialog>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				dialogVisible: false,
				size: '',
				measuredinput: '',
				options: [{
				  value: '1',
				  label: 'Diameter (mm)'
				}, {
				  value: '2',
				  label: 'Circumfence (mm)'
				}],
				measuredvalue: '2',
				diameter: {
					'3': [14.1, 14.5, 45, 46],
					'3.5': [14.5, 14.9, 46, 47],
					'4': [14.9, 15.3, 47, 48],
					'4.5': [15.3, 15.7, 48, 49.3],
					'5': [15.7, 16.1, 49.3, 50.0],
					'5.5': [16.1, 16.5, 50.0, 51.2],
					'6': [16.5, 16.9, 51.2, 53.1],
					'6.5': [16.9, 17.3, 53.1, 54.4],
					'7': [17.3, 17.7, 54.4, 55.7],
					'7.5': [17.7, 18.1, 55.7, 57.0],
					'8': [18.1, 18.5, 57.0, 58.3],
					'8.5': [18.5, 19.0, 58.3, 59.5],
					'9': [19.0, 19.4, 59.5, 60.8],
					'9.5': [19.4, 19.8, 60.8, 62.1],
					'10': [19.8, 20.2, 62.1, 634],
					'10.5': [20.2, 20.6, 63.4, 64.6],
					'11': [20.6, 21.0, 64.6, 65.9],
					'11.5': [21.0, 21.4, 65.9, 67.2],
					'12': [21.4, 21.8, 67.2, 68.5],
					'12.5': [21.8, 22.2, 68.5, 69.7],
					'13': [22.2, 22.6, 69.7, 71.0],
					'13.5': [22.6, 23.01, 71.0, 72.3],
				}
			}
		},
		mounted() {
			 this.size = localStorage.getItem('size') || '';
			 this.measuredvalue = localStorage.getItem('measuredvalue') || 1;
			 this.measuredinput = localStorage.getItem('measuredinput') || '';
		},
		methods:{
			bindDialog() {
				this.dialogVisible = true;
			},
			START() {
				if(this.measuredvalue == 1) {
					this.getDiameter();
				}else {
					this.getCircumfence();
				}
				
			},
			getDiameter() {
				let m = this.measuredinput,
					d = this.diameter,
					size = 'NULL';
				for(let i in d) {
					if(m >= d[i][0] && m < d[i][1]) {
						size = i;
					}
				}
				this.size = size;
			},
			getCircumfence() {
				let m = this.measuredinput,
					d = this.diameter,
					size = 'NULL';
				for(let i in d) {
					if(m >= d[i][2] && m < d[i][3]) {
						size = i;
					}
				}
				this.size = size;
			},
			bindSave() {
				this.dialogVisible = false;
				localStorage.setItem('size', this.size);
				localStorage.setItem('measuredvalue', this.measuredvalue);
				localStorage.setItem('measuredinput', this.measuredinput);
			}
		}
	}
</script>

<style scoped lang="scss">
    .dialogVisible {
    	// top: 50%;
    	// left: 50%;
    	z-index: 9000;
    	position: fixed;
    	width: 100%;
		height: 100%;
    	// transform: translate(-50%, -50%);
    	
    	.title {
            font-size: 0.1667rem;
            color: #666666;
    		margin-top: 0.2604rem;
    		margin-bottom: 0.1042rem;
    	}
    	.list {
			align-items: flex-start;
			
    		.item {
    			width: 2.0833rem;
    			img {
    				width: 2.0833rem;
    				height: 1.3542rem;
    			}
    			.text {
    				font-size: 0.0938rem;
                    color: #666666;
    				margin-top: 0.0521rem;
    			}
    		}
    	}
    	.form {
            border: 2px solid #E4ECF1;
    		padding: 0.2083rem;
    		margin-top: 0.2083rem;
    		.text {
    			font-size: 0.0938rem;
                color: #666666;
    			margin-bottom: 0.1042rem;
    			span {
					width: 1.3021rem;
					display: inline-block;
    				margin-right: 0.4688rem;
    			}
    		}
    		.box {
    			.num {
    				width: 1.3021rem;
    				margin-right: 0.4167rem;
    			}
				
				select {
					width: 1.3021rem;
					border: 2px solid #E4ECF1;
					height: 0.2604rem;
					line-height: 0.2604rem;
					border-radius: 0;
					padding: 0 0.1rem 0 0.05rem;
					margin-right: 0.4167rem;
					
					option {
						width: 1.3021rem;
						border: 2px solid #E4ECF1;
						height: 0.2604rem;
						line-height: 0.2604rem;
						border-radius: 0;
						padding: 0 0.1rem;
					}
				}
    			.btn {
    				cursor: pointer;
    				width: 1.3021rem;
                    height: 0.2604rem;
    				font-size: 0.125rem;
                    color: #FFFFFF;
                    background: #3345CB;
    				&:hover {
    					opacity: 0.6;
    				}
    			}
    		}
    		.text1 {
    			font-size: 0.1667rem;
                color: #333333;
    			margin-top: 0.1563rem;
    		}
    		.text2 {
    			font-size: 0.1146rem;
                color: #666666;
    			margin: 0.0521rem 0 0.1042rem;
    		}
    		.text3 {
    			width: 1.1302rem;
                height: 0.2604rem;
                border: 2px solid #E4ECF1;
    			font-size: 0.0938rem;
                color: #666666;
				
				&:hover {
					color: #3345CB;
				}
    		}
    	}
    	
    	::v-deep .el-dialog__wrapper {
    	    width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
    	}
    	::v-deep .el-dialog {
    	    border-radius: 10px;
			margin: 0;
    	}
    	::v-deep .el-dialog__header {
    	    padding: 0;
    	}
    	::v-deep .el-dialog__body {
    	    padding: 0.1563rem 0.4583rem 0.2083rem;
    	}
    	::v-deep .el-dialog__headerbtn {
    	    font-size: 0.1563rem;
    		top: 0.2083rem;
    		right: 0.2083rem;
    	}
    	::v-deep .el-dialog__close {
    		width: 0.2083rem;
    	    height: 0.2083rem;
    	    line-height: 0.2083rem;
    	    background: #E4ECF1;
    	    border-radius: 10px;
    	    color: #666666;
    	}
    	
    	::v-deep .el-input__inner {
    		border: 2px solid #E4ECF1;
    		width: 1.3021rem;
    		height: 0.2604rem;
    		line-height: 0.2604rem;
    		border-radius: 0;
    	}
    }
	
	
</style>
