const messagesEn = {
	hello: 'Hello world',
	language: 'Language',
	currency: 'Currency',
	copyright: 'Copyright Notice',
	allright: 'All rights reserved.',
	sort: 'Sort',
	filter: 'Filter',
	sort_by: 'Sort By',
	refine_by: 'Refine By',
	price: 'Price',
	average_rating: 'Average rating',
	reviews: 'reviews',
	item_code: 'Item Code',
	qty: 'Quantity',
	register_enable: 'Register Account Enable',
	wholesale_prices: 'Wholesale Prices',
	add_to_cart: 'Add To Cart',
	add_to_favorites: 'Add to Favorites',
	description: 'Description',
	reviews: 'Reviews',
	shipping_payment: 'Shipping & Payment',
	customer_who_also_bought: 'Customers Who Bought This Item Also Bought',
	add_review: 'Add Review',
	view_all_review: 'View  All Review',
	you_must_chosen: 'you must chosen',
	cart: 'Cart',
	discount_codes: 'Discount Codes',
	subtotal: 'Subtotal',
	sub_weight: 'Sub Weight',
	sub_volume: 'Sub Volume',
	shipping_cost: 'Shipping Cost',
	discount: 'Discount',
	proceed_to_pay: 'Proceed to Pay',
	checkout: 'Checkout',
	welcome_to_checkout: 'Welcome to the checkout,Fill in the fields below to complete your purchase!',
	already_registered: 'Already registered? Click here to login',
	shipping_address: 'Shipping Address',
	new_address: 'New Address',
	first_name: 'First Name',
	last_name: 'Last Name',
	email_address: 'Email',
	emailAddress: 'Email',
	telephone: 'Telephone',
	street: 'Street',
	country: 'Country',
	state: 'State',
	city: 'City',
	zip_code: 'Zip Code',
	create_an_account: 'CREATE ACCOUNT',
	password: 'Password',
	confirm_password: 'Confirm Password',
	payment_method: 'Payment Method',
	shipping_method: 'Shipping Method',
	coupon_codes: 'Coupon codes (optional)',
	enter_your_coupon_code: 'Enter your coupon code if you have one.',
	review_your_order: 'Review your order',
	place_order_now: 'Place order now',
	return_policy: 'Return Policy',
	privacy_policy: 'Privacy Policy',
	contact_us: 'CONTACT US',
	about_us: 'About Us',
	my_favorite: 'My Favorite',
	my_order: 'My Order',
	shopping_cart: 'Shopping Cart',
	account: 'Account',
	home: 'Home',
	sign_in: 'Sign In',
	forgot_password: 'Forgot Your Password?',
	register: 'Register',
	login: 'Login',
	register_account: 'Register Account',
	my_account: 'My Account',
	logout: 'Logout',
	edit_account: 'Edit Account',
	change_password: 'Change Password',
	add_new_address: 'Add New Address',
	default_address: 'Default Address',
	name: 'Name',
	address: 'Address',
	operation: 'Operation',
	customer_address: 'Customer Address',
	reorder: 'Reorder',
	view_order: 'View Order',
	order_increment_id: 'Order #',
	date: 'Date',
	operate: 'Operate',
	customer_order: 'Customer Order',
	order_status: 'Order Status',
	order_date: 'Order Date',
	shipping_address: 'Shipping Address',
	shipping_method: 'Shipping Method',
	tracking_number: 'Tracking Number',
	payment_method: 'Payment Method',
	items_ordered: 'Items Ordered',
	product_image: 'Product Image',

	grand_total: 'Grand Total',
	product_info: 'Product Info',
	product_review: 'Product Review',
	product_favorite: 'Product Favorite',
	send_authorization_code: 'Send Authorization Code',
	forget_password: 'Forget Password',
	click_here: 'click here',
	to_login: 'to login',
	reset_accout_success: 'Reset you account success, you can ',
	reset_password_success: 'Reset Password Success',
	forget_password_send_success: 'Forget Password Send Success',
	we_send_message_to_your_email: 'We\'ve send a message to your email address',
	follow_the_instructions_provided: 'Please follow the instructions provided in the message to reset your password.',
	donot_receive_email: 'Didn\'t receive the mail from us?',
	click_here_to_retry: 'click here to retry',
	check_your_bulk_junk_email_folder: 'Check your bulk or junk email folder.',
	confirm_your_identity_to_reset: 'Confirm your identity to reset password ,If you still can\'t find it',
	click: 'click',
	support_center: 'support center',
	for_help: 'for help',
	reset_password: 'Reset Password',
	our_email_address: 'Our Email Address',
	contacts: 'Contacts',
	submit: 'SUBMIT',
	captcha: 'Captcha',
	review: 'Review',
	rate: 'Rate',
	summary: 'Summary',
	name: 'Name',
	product_review: 'Product Review',
	add_review: 'Add Review',
	reviews: 'reviews',
	thank_you_for_purchase: 'Your order has been received,Thank you for your purchase!',
	your_order_is: 'Your order # is',
	receive_an_confirm_email: 'You will receive an order confirmation email with details of your order and a link to track its progress.',
	continue_shopping: 'Continue Shopping',
	order_remark: 'Order Remark (optional)',
	fill_order_remark: 'You can fill in the order remark information below',
	average_rating: 'Average Rating',
	stars_5: '5 stars',
	stars_4: '4 stars',
	stars_3: '3 stars',
	stars_2: '2 stars',
	stars_1: '1 stars',
	arm: 'Style',
	accessories: 'Accessories',
	material: 'Material',
	// Customization: 'Customization',
	Customization: 'Material',
	styles: 'Styles',
	pleaseSelect: 'please select',
	sorting: 'Comprehensive sorting',
	Filter: 'Filter',
	category: 'Category',
	gem: 'Gem',
	Price: 'Price',
	getCode: 'Get Code',
	securityCode: 'Security code',
	signUp: 'Sign Up',
	please: 'Please enter',
	pleases: 'Please enter',
	terms: 'Terms and conditions',
	privacy: 'Privacy Policy',
	Already: 'Already have an account?',
	and: 'and',
	Agr: `By creating an account, you agree that your data will be used in accordance with {n}'s`,
	agr2: 'Check this box if you DO NOT WISH to receive marketing materials from us in the future.',
	LOGIN: 'LOG IN',
	LOGINF: 'Please log in to your  {n} account',
	LOGINFA: 'Create an account',
	LOGINFB: 'Create an account to enjoy special offers, faster check out, order viewing  & tracking.',
	FORGOT: 'FORGOT PASSWORD?',
	FORGOTF: 'To reset your password, please enter your email address',
	ResetPassword: 'Reset password',
	ResetPasswordA: 'Confirm the new password',
	ProceedLog: 'Proceed to Log In',
	Information: 'My Information',
	Orders: 'My Orders',
	Appointment: 'My Appointment',
	SignOut: 'Sign out',
	LAA: 'Welcome to login to enjoy additional benefits :',
	LAB: 'Easy access to wishlist, order history',
	LAC: 'Faster checkout with stored shipping and billing information',
	LAD: 'customized design record',
	LAE: 'Log in',
	LAF: 'Create an account',
	SizeElectionGuide: 'Ring Size Guide',
	addCart: 'Add To Cart',
	CheckOut: 'Check Out',
	Recommend: 'Recommend to you',
	Recently: 'You May also Like',
	SearchComments: 'Search comments',
	QUERY: 'Search',
	serviceFA: 'Find the local {n} DEMO SHOP where you can schedule a personalized consultation with our jewelry expert.',
	serviceFB: 'Find now',
	serviceFC: 'FREE HONG KONG SHIPPING',
	serviceFD: 'Quality Packaging',
	serviceFE: 'Free Engraving',
	serviceFF: 'Diamond Certificate',
	Details: 'Details',
	ProductDetails: 'Product Details',
	Descriptionworks: 'Description of works',
	OnlineConsultan: 'whatsApp',
	whatsApp: 'whatsApp',
	ShoppingCart: 'Shopping cart',
	currentShopping: 'The current shopping cart',
	currentWorks: 'works',
	ChooseAll: 'Choose All',
	Validation: 'Additional Certificate',
	CHANGE: 'CHANGE',
	TOTAL: 'TOTAL',
	TAXESDUTIES: 'TAXES & DUTIES:',
	TAXESDUTIESFA: 'Import Taxes are included for shipment to US & Hong Kong.',
	TAXESDUTIESFB: 'For shipment outside US & Hong Kong, import taxes, duties, or customs clearance fees will have to be paid to the delivery courier at delivery.',
	Amount: 'Amount',
	OrderInformation: 'Order Information',
	Items: 'items',
	Subtotal: 'Subtotal',
	PromotionCode: 'PROMO CODE',
	promocode: 'Enter PROMO CODE to get extra discount',
	cancel: 'CANCEL',
	CONFIRM: 'CONFIRM',
	NOTE: 'NOTE:',
	NOTEFA: 'There is no refund & exchange for sale items, engraved products & customized products. Please ref to our Return & Exchange Policy for more details.',
	Need: 'Need help?',
	NeedFA: 'Return and exchange policy',
	NeedFB: 'An email will be sent to you to confirm the order details of the customized product. ',
	Remove: 'Remove',
	Viewall: 'View all',
	Select: 'Select',
	ShoppingCart: 'Shopping Cart',
	cartEmpty: 'Your shopping cart is empty',
	MyDesign: 'My Design',
	designYet: `You haven't started your design yet.`,
	Starthere: 'Start here!',
	GoStudio: 'Go To 3D {n} Studio',
	Wishlist: 'Wish list',
	listEmpty: 'Your wish list is empty',
	Accountinformation: 'Account information',
	accountOrderFA: 'The login account will be used to track the status of your order',
	accountOrderFB: 'Orders cannot be shipped to a P.O. Box.',
	accountOrderFC: 'Please provide a street address where a signature can be obtained for receipt.',
	CreateAccount: 'Create an account',
	Email: 'E-mail',
	verificationCode: 'Get verification code',
	SecurityCode: 'Security code',
	Shippinginformation: 'Shipping information',
	StandardLogistics: 'Standard Logistics',
	PickStore: 'Pick up at a Store',
	InstructionsStore: 'Instructions for mailing to the store',
	Theeditor: 'Edit',
	Title: 'Title',
	FirstName: 'First Name',
	LastName: 'Last Name',
	PhoneNumber: 'Phone Number',
	StateProvince: 'State/Province',
	PostalCode: 'Zip / Postal Code',
	AddressLine1: 'Address Line 1',
	AddressLine2: 'Address Line 2',
	PaymentMethod: 'Payment Method',
	PromoCode: 'PROMO CODE',
	PromoCode1: 'PROMO PRICE',
	Postscript: 'Special Requirement',
	PostscriptFA: 'If you have any other requirements, please fill in here',
	PaymentAgrFA: 'I understand and accept the',
	PaymentAgrFB: 'Terms and Conditions',
	PaymentAgrFC: 'including',
	PaymentAgrFD: 'Payment',
	PaymentAgrFE: 'Return Policy',
	PaymentAgrFF: 'By checking this box, I agree to the',
	PaymentAgrFG: 'Privacy Policy',
	PaymentAgrFH: 'and consent to the given information being used by {n}.',
	paymentworks: '{count} works have been selected for settlement and payment',
	ShippingInsurance: 'Shipping and insurance',
	Findme: 'Find a shop near me',
	selectLocation: 'Please select location',
	storesfound: '{count} stores have been found',
	CHOOSE: 'CHOOSE',
	YourAccount: 'Your account',
	MyDetails: 'Personal Details',
	Orders: 'Orders',
	Return: 'Return',
	MyAppointment: 'My Appointment',
	Account: 'Account Setting',
	OAA: 'Email',
	OAC: 'Your current email',
	OAD: 'Password',
	OAE: 'Enter the below information to change your password',
	OAF: 'Your new password',
	OAG: 'Confirm Again',
	OAH: 'Your current telephone number is',
	OAI: 'new number',
	SAVE: 'SAVE',
	Birthday: 'Birthday',
	Relationship: 'Relationship status',
	Unmarried: 'Unmarried',
	married: 'married',
	engagement: 'The engagement',
	anniversary: 'What is your anniversary?',
	OrderNo: 'Order No',
	Custom: 'Custom',
	Existing: 'Existing works',
	Waiting: 'Waiting for payment',
	WaitingFA: 'Your order has been created, please continue to complete the payment; If the payment is not made within 60 minutes, the order will be cancelled automatically.',
	WaitingFB: 'Payment processing in progress',
	WaitingFC: 'Payment success',
	WaitingFD: 'Fraud',
	InReview: 'In Review',
	InReviewFA: 'Our customer service team has received your order and is currently reviewing it for you.',
	wSend: 'The order has been confirmed',
	wSendFA: 'Customer service has confirmed your order. We will arrange the order processing and delivery to you as soon as possible.',
	wGoods: 'Your order has been shipped',
	wGoodsFA: 'Please check the tracking number of the shipper to check the logistics status.',
	wreceived: 'Your order has been received',
	wEnd: 'Your order is complete',
	wEndFA: 'Sign and receive orders. Thank you for shopping at {n} and hope you like your new jewelry! Please give us your comments and suggestions. We hope to see you again soon!',
	wExpired: 'Your order has been cancelled',
	wExpiredFA: 'Your order has been cancelled',
	wExpiredFB: 'Cancel',
	paymentOverdue: 'Your payment is overdue',
	orderComplete: 'Your order is complete',
	orderShipped: 'Your order is shipped',
	OrderConfirmed: 'Order confirmed',
	PleasePatient: 'Please be patient',
	PickupCode: 'Pickup code',
	RemainingTime: 'Remaining payment time',
	ReturnShopping: 'Return to shopping',
	WRITEREVIEW: 'WRITE A REVIEW',
	ConfirmReceipt: 'Confirm Receipt',
	applyRefund: 'To apply for a refund',
	CREATE: 'CREATE',
	PAYMENT: 'PAYMENT',
	ORDERCOMFIRMED: 'COMFIRMED',
	Shipped: 'Shipped',
	Complete: 'Complete',
	LogisticsInfo: 'Logistics Information',
	CustomDesign: 'Custom Design',
	Setting: 'Setting',
	CenterStone: 'Center Stone',
	Engravingfee: 'Engraving fee',
	gender0: 'secret',
	gender1: 'Mr.',
	gender2: 'Ms.',
	timelineFA: '* Here shows the standard logistics related information, dynamic configuration by the background',
	Certification: 'Additional Certification (Optional)',
	RefundFA: 'Request for Return/ Refund',
	RefundFB: 'Effective within ({count}) days of receiving the item, except customized item. Submit your application and our customer representative will get back to you on the follow up actions.',
	RefundFC: 'How is this product selected?',
	RefundFD: 'Reasons for return and replacement',
	RefundFE: 'Detailed description',
	RefundFF: 'Picture information',
	RefundFG: 'To help us solve the problem better, please upload the picture',
	RefundFH: 'Return Type',
	salesAfterFA: 'In Review',
	salesAfterFB: 'Our customer service team has received your refund request and is currently reviewing it for you',
	salesAfterFC: 'Refund denied',
	salesAfterFD: `sorry, but your request has been denied. If you have any questions, please contact our customer service representative. We hope to see you again soon!`,
	salesAfterFE: 'Refund success',
	salesAfterFF: 'Your refund has been approved and the amount requested has been returned to you via your original payment method. Please check.',
	salesAfterFG: 'Out of Stock',
	// salesAfterFH: 'We are very sorry. Each brick and stone is unique, the diamonds you chose are out of stock, we have selected several diamonds with similar prices, 4C diamonds for you to replace, still can not meet your requirements, we will return the amount through your original payment method. Thank you for your understanding and hope to see you soon!',
	salesAfterFH: 'We are sorry to inform you that your return request has been rejected. Based on our inspection results, we are unable to accept the returned item(s). If you have any questions or concerns, please do not hesitate to contact us. We will do our best to assist you.',
	salesAfterFI: 'Business Reply：',
	salesAfterFJ: 'Review By',
	salesAfterFK: 'Review passed, waiting for merchant refund',
	salesAfterFL: 'Refund has been cancelled',
	applyTime: 'To apply for time',
	remark: 'remark',
	Actual: 'Actual Refund',
	rActual: 'Amount Refundable',
	Application: 'Application details',
	VisitingAppointment: 'Visiting appointment',
	RequestAppointment: 'Request for Appointment: Choose a store of you preference, and enjoy the custom design experience with the support of our friendly staff. Complete the form and submit, we will contact you for confirmation via phone or email.',
	Visitingappointment: 'Visiting appointment',
	Reservationinformation: 'Reservation information',
	Appointmenttime: 'Appointment date and time',
	Pleaseelaborate: 'Please elaborate on how we can help you.',
	Yourselection: 'Your selection',
	DoOptional: 'Do you already have something in mind? (Optional)',
	Chooseyou: 'Choose a style that appeals to you.',
	CONFIRM: 'CONFIRM',
	Businesshours: 'Business hours',
	aFA: 'We will contact you',
	aFB: 'We have received your request and will contact you within 48 hours to arrange an in-store appointment.',
	aFC: 'Your selection',
	aFD: 'Appointment Confirmed',
	aFE: 'Hello��',
	aFF: 'Welcome to use {title} online store reservation service. Your application for "{count} - Store" has been approved.',
	aFG: 'I choose the',
	aFH: 'The shop is closed!',
	Purpose: 'Purpose',
	appointmenttime: 'appointment time',
	CHOOSESETTING: 'CHOOSE THIS SETTING',
	Modify: 'Modify',
	Compare: 'Compare',
	DCompare: 'Cancel Compare',
	law: 'Website policy and legal statement',
	edu: 'Education',
	problem: 'Common problem',
	color: 'Color',
	clarity: 'Clarity',
	cut: 'Cut',
	symmetry: 'Symm',
	polish: 'Polish',
	fluor: 'Fluorescence',
	drill: 'Diamond Finder',
	replace: 'change',
	latest: 'latest',
	weight: 'weight',
	Ingem: 'Select',
	shape: 'shape',
	Results: 'Results',
	Comparison: 'Comparison',
	indexFA: 'Customize by Category',
	indexFB: 'Unique and tasteful items, let you gift your loved ones, give to your friends, or match with different fashion styles',
	indexFC: 'Design your own ring',
	indexFD: 'Provide exclusive customized services, from engagement diamond rings to wedding rings, freely create your own unique love story',
	indexFE: 'Start with ring styles',
	indexFF: 'Start with diamonds',
	indexFG: 'Featured presentation',
	indexFH: 'Put the light of your love on the red line of fate and make our stories different',
	indexFI: 'Discover More ＋',
	indexFJ: 'Custom Designs',
	indexFK: 'Design Ready',
	indexFL: 'BESTSELLERS',
	indexFM: 'Start with Diamond',
	indexFN: 'Please select your shipping destination and currency',
	indexFO: 'Start with Settings',
	Countries: "Shipping To",
	dialogVisibleFA: 'Measure my finger',
	dialogVisibleFB: 'Take a non-stretchable piece of string, ribbon, thread, or tissue paper. Wrap it snugly around your ring finger or other fingers, near your knuckle',
	dialogVisibleFC: 'Carefully mark with a pen where the strings overlap. For better accuracy, make the smallest mark possible',
	dialogVisibleFD: 'The length of the open label. Use a ruler to measure millimeters or inches. Enter your measurements below',
	dialogVisibleFE: 'Enter the value you measured',
	dialogVisibleFF: 'Calculation',
	dialogVisibleFG: 'Measurement result: your size is',
	dialogVisibleFH: 'Do you want to save the measured size to your account ?',
	dialogVisibleFI: 'Yes, I want to save it',
	dialogVisibleFJ: 'START',
	failure: 'Failure',
	Records: 'Records',
	Recommends: 'Recommend',
	Recommends1: 'Most Often Styled With',
	ProductDescription: 'Product Description',
	User: 'User',
	OrderDetail: 'The Order Details',
	Comments: 'COMMENTS',
	Score: 'Score',
	Review: 'Review',
	Anonymously: 'Post Anonymously',
	POST: 'POST',
	CommentsFA: 'How is this product selected?',
	CommentsFB: 'I choose it as a surprise / gift',
	CommentsFC: 'I collaborated with my partner',
	CommentsFD: 'My partner chose it for herself/himself',
	CommentsFE: 'I chose it as a gift for myself',
	CommentsFF: 'On a scale from 1-10, how likely are you to recommend Ju mart to your friends or colleagues?',
	CommentsFG: 'Comment on',
	CommentsFH: 'Is this comment useful to you?',
	ringSetting: 'Ring Setting',
	CertificateNumber: 'Certificate Number',
	FindAStore: 'Find A Store',
	Size: 'Size',
	WEACCEPT: 'WE ACCEPT',
	CTA: 'Please select Protocol.',
	PAYMENTSUCCESS: 'PAYMENT-SUCCESS',
	PAYMENTSUCCESS1: 'Our customer service team has received your order and is currently reviewing it.',
	PAYMENTSUCCESS2: 'CONTINUE PURCHASE',
	PAYMENTFAILURE: 'PAYMENT FAILURE',
	PAYMENTFAILURE1: 'Payment failed, please pay again',
	PAYMENTFAILURE2: 'PROCEED TO PAYMENT',
	PAYMENTSUCCESSD: 'DETAIL',
	PAYMENTSUCCESSR: 'RETURN',
	JUMARTEMAIL: 'info@JU-MART.com',
	PAYFA: 'The delivery information cannot be empty！',
	PAYFB: 'Please select store！',
	store: 'Store',
	MoreInformation: 'More Information',
	Prompt: 'Prompt',
	Logged: `You are not logged in yet. Would you like to log in?`,
	Exceeding: 'alphanumeric combination up to 20 characters in length',
	Accept: 'Accept',
	Reject: 'Reject',
	alertdialog: 'We use cookies to provide the best website experience for visitors. These include analytics, functionality, and targeting cookies, which will also benefit our marketing efforts. Click the "Accept" button to agree to use cookies. To withdraw non-essential cookies, click Reject.',
	ExperienceCustomization: 'Experience Customization',
	MAKEAAPPOINTMENT: 'MAKE A APPOINTMENT',
	ExcludingTaxes: 'Excluding taxes',
	ExcludingTaxes1: 'excl taxes',
	ExcludingTaxes2: 'Taxes are excluded',
	GoodsRefunded: 'Goods cannot be refunded',
	subscribeFA: 'Get Exclusive Offers and News',
	subscribeFB: 'Your Email',
	EMAIL: 'Email',
	subscribeFC: 'SIGN UP',
	subscribeFD: 'Thanks for subscribing!',
	ExhibitionChain: 'Exhibition Chain type',
	Choose: 'Choose',
	Cancels: 'Cancel',
	FindNear: 'Find the closest service center near me',
	YouDesigned: 'You have currently designed',
	ViewAll: 'View All',
	CompareDesigns: 'Compare Designs',
	YouCrrently: 'You currently have',
	Hi: 'Hi',
	Nologistics: 'No logistics information is available',
	Exploremore: 'Explore more',
	originalPrice: 'Original Price',
	Return: 'Return to the List',
	designFA: 'Choose a',
	designFB: 'SETTING',
	designFC: 'Diamond/ Gemstone',
	designFD: 'Design',
	designFE: 'complete',
	designFF: 'Choose This Diamond',
	designFG: 'This setting has no corresponding diamond',
	Location: 'Location',
	Coffee: 'Coffee',
	Browsinghistory: 'Browsing history',
	NotFun: 'Product is not exist or off the shelf',
	ReceiveGift: 'Receive Gift',
	PaymentAgrFZ: '請注意您購買的是包含了贈品的聯動產品，一旦完成購買，將不能進行退錢、退貨或是換貨，請確認後再購買。',
}
export default messagesEn
