<template>
	<div class="content flexJA">
		<el-empty :description="$t('message.NotFun')"></el-empty>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	export default {
		data(){
			return{
				
			}
		},
		computed: mapState([ 
			'userInfo'
		]),
		mounted() {
			
		}
	}
</script>

<style scoped lang="scss">
	
</style>