<template>
	<!-- 下拉菜单 -->
	<div class="menu" v-if="type">
		<!-- 3d工作室 -->
		<div class="studio box flexJA flexSb" v-if="type == 'studio'">
			<div class="menu-left flexJA flexFs" v-for="(item, index) in child">
				<div class="item">
					<div class="title flexJA flexFs">{{ item.name }}</div>
					<div class="list">
						<div class="item flexJA flexFs" v-for="(items, j) in item.child" :key="j"
							@click="bindNav(`/goods?id=${items.id}`, items)">
							<div class="icon flexJA" v-if="items.thumbnail_image"><img v-lazy="items.thumbnail_image"></div>
							<div class="text">{{ items.name }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="menu-right" v-if="data.image">
				<img class="bg" v-lazy="data.image">
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	props: {
		type: {
			type: String,
			default: ''
		},
		data: {
			type: Object,
			default: {}
		},
		child: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {

		}
	},
	components: mapState([
		'TITLE'
	]),
	mounted() {
		console.log(this.type)
	},
	methods: {
		bindNav(url, item) {
			if (url == this.$route.fullPath) return;
			this.$store.commit('gotMetaInfo', {
				title: `${this.TITLE} ${item.title}`,
				Keywords: item.meta_keywords || this.TITLE,
				description: item.meta_description || this.TITLE
			})
			this.$router.push(url);
		}
	}
}
</script>

<style scoped lang="scss">
.box::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.menu {
	height: 0;
	position: absolute;
	max-height: 2.677083rem;
	left: 50%;
	top: 0.36rem;
	overflow: hidden;
	width: 100vw;
	transform: translateX(-50%);
	background: #fbfbfb;
	z-index: 99;
	transition: all 0.25s;

	.box {
		overflow: hidden;
		overflow-y: auto;
		height: 2.708333rem;
		align-items: start;
		width: 8.3333rem;
		margin: 0 auto;
		background: #fbfbfb;
		padding-left: 0.1042rem;
	}

	.title {
		font-size: 16px;
		font-weight: bold;
		color: #333333;
		padding-bottom: 0.0521rem;
		cursor: pointer;
	}

	.studio {
		.menu-left {
			flex-wrap: wrap;

			.list {
				.item {
					cursor: pointer;
					margin: 0.0625rem 0;

					.icon {
						width: 40px;
						height: 20px;
						margin-right: 0.1042rem;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}

					.text {
						font-size: 16px;
						text-align: left;
						color: #000;
					}

					&:hover {
						.text {
							color: #3345CB;
						}
					}
				}
			}
		}

		.menu-right {
			.bg {
				width: 4.0208rem;
				height: 2.2604rem;
			}
		}
	}

	.product {
		.product-left {
			width: 1.9896rem;

			.list {
				.item {
					cursor: pointer;
					margin: 0.0625rem 0;
					font-size: 0.0729rem;

					font-weight: 400;
					color: #333333;

					&:hover {
						color: #3345CB;
					}
				}
			}
		}

		.product-centre {
			flex: 1;
			position: relative;
			margin-left: 0.0938rem;

			&::after {
				position: absolute;
				top: 0;
				left: -0.0938rem;
				content: '';
				width: 0.0104rem;
				height: 2.2604rem;
				background: #999999;
				display: block;
			}

			.list {
				.item {
					cursor: pointer;
					margin: 0.0625rem 0;
					font-size: 0.0729rem;

					font-weight: 400;
					color: #333333;

					&:hover {
						color: #3345CB;
					}
				}
			}
		}

		.product-right {
			.bg {
				width: 4.0208rem;
				height: 2.2604rem;
			}
		}
	}

	.jewelry {
		flex-wrap: wrap;
		position: relative;

		.jewelry-item {
			position: relative;
			width: 25%;
			margin-bottom: 0.1042rem;

			.list {
				.item {
					cursor: pointer;
					margin: 0.0625rem 0;
					font-size: 0.0729rem;

					font-weight: 400;
					color: #333333;

					&:hover {
						color: #3345CB;
					}
				}
			}

			&:nth-child(4) {
				&::after {
					position: absolute;
					top: 0;
					left: -25px;
					content: '';
					width: 0.0104rem;
					height: 1.4583rem;
					background: #999999;
					display: block;
				}
			}
		}

		&::before {
			position: absolute;
			top: 0;
			left: 2.0521rem;
			content: '';
			width: 0.0104rem;
			height: 3.5208rem;
			background: #999999;
			display: block;
		}

		&::after {
			position: absolute;
			top: 0;
			left: 4.1354rem;
			content: '';
			width: 0.0104rem;
			height: 3.5208rem;
			background: #999999;
			display: block;
		}
	}
}</style>