import en from 'element-ui/lib/locale/lang/en'
import es from 'element-ui/lib/locale/lang/es'
import zh from 'element-ui/lib/locale/lang/zh-CN'
import tw from 'element-ui/lib/locale/lang/zh-TW'

const messages = {
	en: en,
	es: es,
	zh: zh,
	tw: tw
}

export default messages