import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index'
import NotFun from '@/views/404'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		component: Index,
		meta: {
			title: 'Index'
		}
	},
	{
		path: '/goods',
		name: 'goods',
		component: () => import('@/views/goods'),
		meta: {
			title: 'goods'
		}
	},
	{
		path: '/:lang/goods/:id*',
		name: 'goods',
		component: () => import('@/views/goods1'),
		meta: {
			title: 'goods'
		}
	},
	{
		path: '/details',
		name: 'details',
		component: () => import('@/views/goodsDetails')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/register')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login')
	},
	{
		path: '/forgetPassword',
		name: 'forgetPassword',
		component: () => import('@/views/forgetPassword')
	},
	{
		path: '/comment',
		name: 'comment',
		component: () => import('@/views/comment')
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('@/views/search')
	},
	{
		path: '/inventory',
		name: 'inventory',
		component: () => import('@/views/inventory')
	},
	{
		path: '/article',
		name: 'article',
		component: () => import('@/views/article'),
		meta: {
			title: 'article'
		}
	},
	{
		path: '/article/:tid/:aid/:class/:title',
		name: 'Article',
		component: () => import('@/views/article1'),
		meta: {
			title: 'article'
		}
	},
	{
		path: '/policy',
		name: 'policy',
		component: () => import('@/views/policy')
	},
	{
		path: '/commonProblem',
		name: 'commonProblem',
		component: () => import('@/views/commonProblem')
	},
	{
		path: '/educationList',
		name: 'educationList',
		component: () => import('@/views/educationList')
	},
	{
		path: '/designVersion',
		name: 'designVersion',
		component: () => import('@/views/designVersion')
	},
	{
		path: '/appointment/inquire',
		name: 'appointmentInquire',
		component: () => import('@/views/appointment/inquire')
	},
	{
		path: '/appointment/details',
		name: 'appointmentDetails',
		component: () => import('@/views/appointment/details')
	},
	{
		path: '/appointment/succeed',
		name: 'appointmentSucceed',
		component: () => import('@/views/appointment/succeed')
	},
	{
		path: '/myOrder',
		name: 'myOrder',
		redirect: '/myOrder/message',
		component: () => import('@/views/myOrder/index'),
		children: [
			{
				path: 'message',
				name: 'message',
				component: () => import('@/views/myOrder/message'),
				
			},
			{
				path: 'order',
				name: 'order',
				component: () => import('@/views/myOrder/order')
			},
			{
				path: 'returnDetails',
				name: 'returnDetails',
				component: () => import('@/views/myOrder/returnDetails')
			},
			{
				path: 'appointment',
				name: 'appointment',
				component: () => import('@/views/myOrder/appointment')
			},
			{
				path: 'account',
				name: 'account',
				component: () => import('@/views/myOrder/account')
			},
			{
				path: 'details',
				name: 'details',
				component: () => import('@/views/myOrder/orderDetails')
			},
			{
				path: 'return',
				name: 'return',
				component: () => import('@/views/myOrder/return')
			}
		]
	},
	{
		path: '/shoppingCart',
		name: 'shoppingCart',
		component: () => import('@/views/shoppingCart')
	},
	{
		path: '/paymentInfo',
		name: 'paymentInfo',
		component: () => import('@/views/paymentInfo')
	},
	{
		path: '/paycallback',
		name: 'paycallback',
		component: () => import('@/views/paycallback')
	},
	{
		path: '/stripeback',
		name: 'stripeback',
		component: () => import('@/views/stripeback')
	},
	{
		path: '/variable',
		name: 'variable',
		component: () => import('@/views/vplus3d/variable')
	},
	{
		path: '/design',
		name: 'design',
		component: () => import('@/views/vplus3d/design')
	},
	{
		path: '/designDetails',
		name: 'designDetails',
		component: () => import('@/views/vplus3d/designDetails')
	},
	{
		path: '/variableDetails',
		name: 'variableDetails',
		component: () => import('@/views/vplus3d/variableDetails')
	},
	{
		path: '/commodityDetails',
		name: 'commodityDetails',
		component: () => import('@/views/vplus3d/commodityDetails')
	},
	{
		path: '/preview',
		name: 'preview',
		component: () => import('@/views/vplus3d/preview')
	},
	{
		path: '/design/ring',
		name: 'ring',
		component: () => import('@/views/design/ring'),
		meta: {
			title: 'ring'
		}
	},
	{
		path: '/design/diamond',
		name: 'diamond',
		component: () => import('@/views/design/diamond'),
		meta: {
			title: 'diamond'
		}
	},
	{
		path: '*',
		name: 'NotFun',
		component: NotFun
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
