// 获取token
export function getTokenAUTH() {
	return localStorage.getItem('token');
}

//解决整数没有小数点方法
export function amend(num1, num2, symbol) {
	var str1 = num1.toString(),
		str2 = num2.toString(),
		result, str1Length, str2Length
	try {
		str1Length = str1.split('.')[1].length
	} catch (error) {
		str1Length = 0
	}
	try {
		str2Length = str2.split('.')[1].length
	} catch (error) {
		str2Length = 0
	}
	var step = Math.pow(10, Math.max(str1Length, str2Length))
	switch (symbol) {
		case "+":
			result = (num1 * step + num2 * step) / step
			break;
		case "-":
			result = (num1 * step - num2 * step) / step
			break;
		case "*":
			result = ((num1 * step) * (num2 * step)) / step / step
			break;
		case "/":
			result = (num1 * step) / (num2 * step)
			break;
		default:
			break;
	}
	return result
}


export function EngraveTest(text) {
	let rx = /[a-z\d]/i,
		rxcn = /[\u4e00-\u9fa5]/,
		num = 0,
		chr;
	for (var i = 0, j = text.length; i < j; i++) {
		chr = text.charAt(i);
		if (rx.test(chr)) num += 1;
		else if (rxcn.test(chr)) num += 2;
		else num += 2;
		// console.log(chr);
	}
	return num;
}
