<template>
	<div class="content">
		<!-- 轮播图 -->
		<el-carousel trigger="click" :height="advHeight" :interval="6000" v-if="advertiseImg.pc">
			<el-carouselItem v-for="(item, index) in advertiseImg.pc" :key="item">
				<div class="item flexJA" @click="bindOpen(item.url)"><img :src="item.image" class="img"></div>
			</el-carouselItem>
		</el-carousel>
		<!-- 视频 -->
		<div class="video flexJA" v-if="introduction">
			<div class="left">
				<div class="wh100" v-if="introduction.vedio">
					<video id="vidoe" class="wh100" :src="introduction.vedio" :poster="introduction.image"
						:controls="play"></video>
					<div class="wh100 pc flexJA" v-if="!play" @click.stop="bindPaly">
						<img src="@/assets/images/16.png">
					</div>
				</div>
			</div>
			<div class="right">
				<!-- <a href="https://api.whatsapp.com/send?phone=+94870011&text=Hello">点我WhatsApp聊天</a> -->
				<!-- <div class="title flexJA uppercase" v-html="introduction.name"></div> -->
				<div class="desc wh100" v-html="introduction.content"></div>
			</div>
		</div>

		<!-- 类别定制 -->
		<div class="category" v-if="customize.info">
			<div class="title flexJA uppercase" v-html="customize.title"></div>
			<div class="desc flexJA" v-html="customize.description"></div>
			<div class="list flexJA flexFs">
				<router-link class="item flexJA flexDc flexSb" v-for="(item, index) in customize.info" :key="index"
					:to="`/goods?id=${item.category_id}`">
					<div class="imgs flexJA"><img v-lazy="item.image"></div>
					<div class="name flexJA">{{ item.title }}</div>
				</router-link>
			</div>
		</div>

		<!-- 设计 -->
		<div class="design flexJA" v-if="data.design_your_rings">
			<div class="left"><img v-lazy="data.design_your_rings.image" class="wh100"></div>
			<div class="right flexJA flexDc">
				<div class="title uppercase" v-html="data.design_your_rings.title"></div>
				<div class="desc" v-html="data.design_your_rings.description"></div>
				<div class="list flexJA " v-if="data.design_your_rings.info">
					<div class="item" style="margin: 0 10px;"><router-link class="btn flexJA banLine"
							:to="`/design/Diamond`">{{ $t("message.indexFF") }}</router-link></div>
					<div class="item" style="margin: 0 10px;"><router-link class="btn flexJA banLine"
							:to="`/design/Ring`">{{ $t("message.indexFO") }}</router-link></div>
				</div>
			</div>
		</div>

		<!-- 合作伙伴设计师 -->
		<div class="designers flexJA" v-if="data.partner_designers && data.partner_designers.length">
			<div class="carousel">
				<el-carousel trigger="click" :autoplay="false" height="2.916667rem">
					<el-carouselItem v-for="(item, index) in data.partner_designers" :key="index">
						<router-link class="item flexJA" :to="`/goods?id=${item.category_id}`">
							<div class="left"><img :src="item.avatar" class="wh100"></div>
							<div class="right">
								<div class="title uppercase" v-html="item.title"></div>
								<div class="desc" v-html="item.content"></div>
								<!-- <div class="btn flexJA flexFs">
																	<div class="flexJA">{{$t("message.indexFJ")}}</div>
									<div class="flexJA">{{$t("message.indexFK")}}</div>
								</div> -->
							</div>
						</router-link>
					</el-carouselItem>
				</el-carousel>
			</div>
		</div>

		<!-- 精选呈现 -->
		<div class="featured" v-if="data.feature_introduction">
			<div class="title uppercase flexJA" v-html="data.feature_introduction.title"></div>
			<div class="desc flexJA" v-html="data.feature_introduction.description"></div>
			<div class="list flexJA flexSb" v-if="data.feature_introduction.info">
				<div class="item" v-for="(item, index) in data.feature_introduction.info">
					<router-link :to="`/goods?id=${item.category_id}`">
						<div class="imgs"><img v-lazy="item.image" class="wh100"></div>
						<div class="name flexJA">{{ item.title }}</div>
						<div class="btn flexJA">{{ $t("message.indexFI") }} >
						</div>
					</router-link>


				</div>
			</div>
		</div>

		<!-- 畅销 -->
		<div class="seller flexJA flexDc">
			<div class="title flexJA">{{ $t('message.indexFL') }}</div>
			<div class="carousel flexJA">
				<el-carousel trigger="click" :autoplay="false" indicator-position="none" height="2.48rem">
					<el-carouselItem v-for="(item, i) in bestList" :key="item">
						<div class="goods flexJA flexFs">
							<div class="item" v-for="(items, j) in item">
								<div @click="bindNav(items.id, items)">
									<div class="imgs flexJA"><img v-lazy="items.image">
										<div class="rate" v-if="items.discountrate">{{ items.discountrate }}%off</div>
									</div>
									<div class="name banLine">{{ items.name }}</div>
									<div class="price flexJA" v-if="!items.discountrate">{{ items.price.code }}{{
										items.price.symbol }} {{ items.price.value }}</div>
									<div class="price disprice flexJA" v-if="items.discountrate">
										<span>{{ items.price.code }} {{ items.price.symbol }}{{ items.price.value }}</span>
										<span class="taxes" v-if="items.setting_price">({{ items.setting_price }})</span>
										<span class="taxes">({{ $t('message.ExcludingTaxes1') }})</span>
									</div>
									<div class="price flexJA flexAE discountrate" v-if="items.discountrate">{{
										items.price.code }} {{ items.price.symbol }} {{ items.discount_price.value }}</div>
									<div class="btn flexJA">Explore more</div>
								</div>

							</div>
						</div>
					</el-carouselItem>
				</el-carousel>
			</div>
		</div>

		<!-- 匠心为爱 -->
		<!-- <div class="ingenuity flexJA flexFe" v-if="data.ingenuity_for_love">
			<div class="left flexJA flexDc">
				<div class="title" v-html="data.ingenuity_for_love.name"></div>
				<div class="desc" v-html="data.ingenuity_for_love.content"></div>
				<router-link class="btn flexJA" :to="`/goods?id=${data.ingenuity_for_love.id}`">
					{{$t('message.indexFM')}}
				</router-link>
			</div>
			<div class="right"><img v-lazy="data.ingenuity_for_love.image" class="wh100"></div>
		</div> -->

		<!-- 钻石 -->
		<!-- <div class="diamond" v-if="card.length">
			<div class="card" style="overflow: hidden;">
				<div class="item flexJA" v-for="(item, index) in card" :class="item.c"><img :src="item.src"></div>
			</div>
			<div class="bottom flexJA">
				<i class="el-icon-arrow-left" @click="bindCardPrev"></i>
				<div class="center" v-for="(item, index) in card" v-if="item.c == 'a'">
					<div class="title uppercase">{{item.title}}</div>
					<div class="desc banLine" style="max-width: 400px;" :title="item.content">{{item.content}}</div>
				</div>
				<i class="el-icon-arrow-right" @click="bindCardNext"></i>
			</div>
		</div> -->

		<!-- 门店 -->
		<div class="store" v-if="data.offline_store">
			<div class="title uppercase flexJA">{{ TITLE }} offline store</div>
			<div class="list flexJA">
				<el-popover class="item" trigger="hover" placement="bottom" close-delay="0"
					v-for="(item, index) in data.offline_store" :title="item.name"
					:content="`${$t('message.Businesshours')}: ${item.business_hour}`">
					<router-link class="item" slot="reference" :to="`/appointment/inquire?l=${index}`">
						<div class="imgs"><img v-lazy="item.image" class="wh100"></div>
						<div class="name">{{ item.name }}</div>
					</router-link>
				</el-popover>
			</div>
		</div>

		<!-- TMT -->
		<div class="TMT" v-if="purchased.pc">
			<!-- <div class="title uppercase flexJA">They all chose TMT</div> -->
			<div class="imgs flexJA"><img v-lazy="purchased.pc.image"></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	data() {
		return {
			play: false,
			card: [],
			data: {},
			customize: {},
			advertiseImg: {},
			introduction: {},
			cardIndex: 0,
			bestList: [],
			diamond: [],
			purchased: '',
			d1: ``,
			advHeight: '3.28rem'
		}
	},
	computed: mapState([
		'userInfo',
		'TITLE'
	]),
	watch: {
		data(e) {
			console.log(e)
			this.$store.commit('gotMetaInfo', {
				title: e.home_page_meta.title,
				Keywords: e.home_page_meta.keywords,
				description: e.home_page_meta.meta
			})
		}
	},
	mounted() {
		this.getHome();
	},
	methods: {
		getHome() {
			this.$api.getHomeIndex().then(res => {
				console.log(res)
				let data = res.data;
				this.data = res.data;
				this.customize = data.customize_category;
				this.introduction = res.data.introduction
				this.advertiseImg = res.data.advertise_img;
				this.imgLoag(this.advertiseImg[0] || '');
				this.d1 = this.d1.replace(/\n/g, '<br/>');
				this.diamond = data.round_diamond;
				this.purchased = data.purchased;

				let bestList = [],
					bestLen = 4,
					best = data.best_selling;
				for (let i = 0; i < Math.ceil(best.length / bestLen); i++) {
					bestList[i] = [];
					bestList[i].push(best[i * bestLen]);
					for (let j = 1; j < bestLen; j++) {
						if (!best[i * bestLen + j]) {
							break
						} else {
							bestList[i].push(best[i * bestLen + j]);
						}
					}
				}
				this.bestList = bestList;
				this.cardInit();




			})
		},
		bindNav(id, item) {
			console.log(item.is_customized, item.customized_type)
			if (item.is_customized == 1) {
				if (item.customized_type == 1) {
					this.$router.push(`/designDetails?id=${id}`);
				} else if (item.customized_type == 2) {
					this.$router.push(`/variableDetails?id=${id}`);
				} else if (item.customized_type == 4) {
					this.$router.push(`/commodityDetails?id=${id}`);
				}

			} else {
				this.$router.push(`/details?id=${id}`);
			}
		},
		imgLoag(e) {
			if (e) {
				let img = new Image();
				img.src = e.image;
				img.onload = (res) => {
					this.advHeight = `${(img.height / 213) || 3.28}rem`;
					console.log()
				}
			}
		},
		cardInit() {
			let diamond = this.diamond;
			let index = this.cardIndex;
			let arr = [];

			if (!diamond.length) return;

			for (let i in diamond) {
				diamond[i].c = 'h';
				diamond[i].src = diamond[i].image;
				arr.push(diamond[i]);
			}
			console.log(arr)
			if (index == diamond.length - 1) {
				arr[index].c = 'a';
				arr[0].c = 'b';
				arr[1].c = 'c';
				arr[index - 1].c = 'd';
				arr[index - 2].c = 'e';
			} else if (index == diamond.length - 2) {
				arr[index].c = 'a';
				arr[index + 1].c = 'b';
				arr[0].c = 'c';
				arr[index - 1].c = 'd';
				arr[index - 2].c = 'e';
			} else if (index == 0) {
				arr[index].c = 'a';
				arr[index + 1].c = 'b';
				arr[index + 2].c = 'c';
				arr[diamond.length - 1].c = 'd';
				arr[diamond.length - 2].c = 'e';
			} else if (index == 1) {
				arr[index].c = 'a';
				arr[index + 1].c = 'b';
				arr[index + 2].c = 'c';
				arr[0].c = 'd';
				arr[diamond.length - 1].c = 'e';
			} else {
				arr[index].c = 'a';
				arr[index + 1].c = 'b';
				arr[index + 2].c = 'c';
				arr[index - 1].c = 'd';
				arr[index - 2].c = 'e';
			}

			this.card = arr;
			console.log(arr)
		},
		bindCardPrev() {
			let i = this.cardIndex;
			this.cardIndex = i - 1 < 0 ? this.diamond.length - 1 : i - 1;
			this.cardInit();
		},
		bindCardNext() {
			let i = this.cardIndex;
			this.cardIndex = i + 1 > this.diamond.length - 1 ? 0 : i + 1;
			this.cardInit();
		},
		bindPaly() {
			this.play = true;
			document.querySelector('#vidoe').play();
			// var element = document.querySelector('#vidoe');
			// if (element.requestFullScreen) {
			// 	element.requestFullScreen();
			// } else if (element.mozRequestFullScreen) {
			// 	element.mozRequestFullScreen();
			// } else if (element.webkitRequestFullScreen) {
			// 	element.webkitRequestFullScreen();
			// }
		},
		bindPause() {
			this.play = false;
			document.querySelector('#vidoe').pause();
		},
		bindOpen(url) {
			if (url) window.open(url);
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep .el-carousel {
	width: 9rem;
	margin: 0 auto;
	min-width: 1400px;
	max-width: 100%;

	.el-carousel__button {
		width: 11px;
		height: 11px;
		background: #B5B5B5;
		border-radius: 50%;
	}

	.is-active {
		.el-carousel__button {
			background: #fff;
		}
	}

	.item {
		width: 100%;
		height: 100%;

		.img {
			width: 100%;
			height: auto;
		}
	}
}

.video {
	width: 8.333333rem;
	margin: 0.416667rem auto;

	.left {
		width: 5rem;
		height: 2.8125rem;
		position: relative;
		overflow: hidden;
		background: #000;

		img {
			cursor: pointer;
			width: 0.848958rem;

			&:hover {
				opacity: 0.8;
			}
		}

	}

	.right {
		width: 3.333333rem;
		height: 2.8125rem;
		overflow: hidden;
		background: #F9F9F9;
		color: #666666;

		.title {
			text-align: center;
			font-weight: bold;
			font-size: 0.166667rem;
			padding: 0.416667rem 0 0.208333rem;
		}

		.desc {
			// padding: 0 0.46875rem;
			// line-height: 0.1875rem;
			// text-align: center;
		}
	}
}

.category {
	width: 8.333333rem;
	margin: 0 auto 0.416667rem;

	.title {
		font-weight: bold;
		font-size: 0.166667rem;
		color: #666666;
		margin-bottom: 0.208333rem;
	}

	.desc {

		color: #666666;
		margin-bottom: 0.208333rem;
	}

	.list {
		.item {
			width: 2.020833rem;
			height: 2.020833rem;
			background: #F0F0F0;
			margin-right: 0.083333rem;

			.imgs {
				width: 100%;
				overflow: hidden;
				height: 1.510417rem;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			.name {
				width: 100%;
				color: #666666;
				font-size: 16px;
				height: 0.3125rem;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}

			&:hover {
				box-shadow: 0 5px 5px #F0F0F0;

				img {
					transform: scale(1.1);
					transition: all 0.3s;
				}

				.name {
					background: rgb(153, 153, 153, 0.25);
				}
			}
		}
	}
}

.design {
	width: 8.333333rem;
	height: 3.072917rem;
	margin: 0 auto 0.416667rem;
	background: #F9F9F9;

	.left {
		width: 3.020833rem;
		height: 100%;
	}

	.right {
		height: 100%;
		width: 3.072917rem;
		padding: 0 0.859375rem;

		.title {
			font-weight: bold;
			font-size: 0.166667rem;
			color: #666666;
			margin-bottom: 0.208333rem;
		}

		.desc {

			line-height: 0.130208rem;
			color: #666666;
			text-align: center;
			margin-bottom: 0.3125rem;
		}

		.item {
			&:nth-child(1) {
				// margin-right: 0.416667rem;
			}

			.imgs {
				width: 1.145833rem;
				height: 0.729167rem;
				margin-bottom: 0.208333rem;
				align-items: flex-end;

				img {
					max-width: 100%;
					height: 100%;
				}
			}

			.btn {
				color: #fff;
				width: 1.145833rem;
				height: 0.3125rem;
				background: #3345CB;
				border-radius: 4px;
				font-size: 0.083333rem;
				cursor: pointer;
			}

			&:hover {
				img {
					transform: scale(1.1);
					transition: all 0.3s;
				}

				.btn {
					opacity: 0.8;
				}
			}
		}
	}
}

.designers {
	margin: 0 auto 0.416667rem;
	background: #F9F9F9;

	.item {
		.left {
			overflow: hidden;
			border-radius: 100%;
			width: 2.041667rem;
			height: 2.041667rem;
			margin-right: 0.520833rem;
		}

		.right {
			width: 4.270833rem;
		}

		.title {
			font-size: 0.125rem;
			color: #333333;
			font-weight: bold;
			margin-bottom: 0.208333rem;
		}

		.desc {

			line-height: 0.177083rem;
			color: #666666;
			max-height: 1.145833rem;
			margin-bottom: 0.104167rem;
			// max-width: 1.5625rem;
			overflow: hidden;
			overflow-y: auto;
		}

		.btn {
			div {
				color: #fff;
				width: 1.041667rem;
				height: 0.3125rem;
				background: #3345CB;
				border-radius: 4px;
				font-size: 0.083333rem;
				cursor: pointer;
				margin-right: 0.416667rem;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}

.featured {
	width: 7.291667rem;
	margin: 0 auto 0.416667rem;

	.title {
		font-weight: bold;
		font-size: 0.166667rem;
		color: #666666;
		margin-bottom: 0.208333rem;
	}

	.desc {

		color: #666666;
		margin-bottom: 0.208333rem;
	}

	.item {
		width: 3.541667rem;

		.imgs {
			width: 100%;
			height: 4.6875rem;
			margin-bottom: 0.208333rem;
		}

		.name {
			font-size: 0.09375rem;
			font-weight: bold;
			color: #666666;
			margin-bottom: 0.104167rem;
		}

		.btn {
			width: 0.833333rem;
			height: 0.208333rem;
			border: 1px solid #3345CB;
			border-radius: 4px;
			font-size: 0.083333rem;
			color: #3345CB;
			margin: 0 auto;
			cursor: pointer;

			&:hover {
				border: 1px solid #f00;
				color: #f00;
			}
		}
	}
}

.seller {
	margin: 0 auto 0.416667rem;
	background: #F9F9F9;
	padding: 0.416667rem 0 0.208333rem;

	.carousel {
		width: 8.072917rem;
	}

	.title {
		font-weight: bold;
		font-size: 0.166667rem;
		color: #666666;
		margin-bottom: 0.208333rem;
	}

	.goods {
		width: 7.291667rem;
		margin: 0 auto;
		align-items: flex-start;
	}

	.item {
		cursor: pointer;
		width: 1.697917rem;
		height: 2.260417rem;
		text-align: center;
		background: #f0f0f0;
		margin-right: 0.166667rem;
		transition: all 0.3s;
		border: 1px dotted #333;

		&:last-child {
			margin-right: 0;
		}

		.imgs {
			width: 100%;
			height: 1.5625rem;
			position: relative;
			margin-bottom: 0.15625rem;

			img {
				max-width: 100%;
				max-height: 100%;
			}

			.rate {
				top: 0;
				left: 0;
				padding: 6px 12px;
				background: #f00;
				color: #fff;
				font-size: 16px;
				position: absolute;
				opacity: 0.9;
			}
		}

		.name {
			width: 80%;
			margin: 0 auto;

			color: #999999;
		}

		.price {
			font-size: 0.09375rem;
			color: #999999;
			margin: 0.1rem 0;
		}

		.disprice {
			color: #999;
			font-size: 0.09375rem;
			margin-bottom: 5px;
			margin-top: 0.02rem;

			span {
				text-decoration: line-through #f00 2px;
			}
		}

		.discountrate {
			color: #f00;
			font-weight: 900;
			margin: 0 0 0.04rem;
			font-size: 0.09375rem;

			span {
				color: #f00;
				font-size: 0.09375rem;
			}
		}

		.taxes {
			color: #999;
			font-weight: 400;
			margin-left: 5px;
			font-size: 12px;
			text-decoration: initial !important;
		}

		.btn {
			width: 0.760417rem;
			height: 0;
			background: #3345CB;
			border-radius: 4px;
			font-size: 0.09375rem;
			color: #FFFFFF;
			margin: 0 auto;
			overflow: hidden;
		}

		&:hover {
			height: 2.46rem;
			border: 1px solid #3345CB;

			.btn {
				height: 0.208333rem;
				transition: all 0.3s;
			}
		}
	}
}

.ingenuity {
	width: 7.291667rem;
	margin: 0 auto 0.416667rem;

	.left {
		width: 3.333333rem;
		height: 2.385417rem;
		background: rgba(249, 249, 249, 0.25);
		border: 2px solid #333333;
		margin-right: -0.833333rem;
		position: relative;
		backdrop-filter: blur(2px);

		.title {
			font-weight: bold;
			font-size: 0.166667rem;
			color: #666666;
			margin-bottom: 0.208333rem;
		}

		.desc {
			max-width: 80%;
			height: 1.197917rem;

			color: #666666;
			margin-bottom: 0.208333rem;
			text-align: center;
			line-height: 0.177083rem;
			overflow: hidden;
			overflow-y: auto;
		}

		.desc::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		.btn {
			color: #fff;
			font-size: 0.083333rem;
			width: 1.458333rem;
			height: 0.208333rem;
			background: #3345CB;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.right {
		width: 4.291667rem;
		height: 3.208333rem;
	}
}



.diamond {
	padding: 0.416667rem 0;
	margin: 0 auto 0.416667rem;
	background: #F9F9F9;

	.card {
		width: 8.177083rem;
		height: 1.635417rem;
		overflow: hidden;
		position: relative;
		margin: 0 auto 0.208333rem;

		.item {
			top: 0;
			left: 3.270833rem;
			width: 1.635417rem;
			height: 100%;
			position: absolute;
			transition: 0.5s;

			img {
				max-width: 0.78125rem;
				max-height: 0.78125rem;
			}
		}


		.a {
			z-index: 10;
			transform: translateX(0);

			img {
				width: 80%;
				max-width: 80%;
				max-height: 80%;
			}
		}

		.b {
			transform: translateX(1.635417rem) perspective(500px);
		}

		.c {
			transform: translateX(calc(1.635417rem * 2)) perspective(500px);
		}

		.d {
			transform: translateX(-1.635417rem) perspective(500px);
		}

		.e {
			transform: translateX(calc(-1.635417rem * 2)) perspective(500px);
		}

		.h {
			opacity: 0;
			transition: all 0;
			transform: translateX(0) perspective(500px);
		}
	}

	.bottom {
		i {
			color: #CCCCCC;
			font-size: 0.229167rem;
			margin: 0 0.177083rem;
			cursor: pointer;

			&:hover {
				color: #3345CB;
			}
		}

		.title {
			font-size: 0.083333rem;
			font-weight: bold;
			color: #666666;
			margin-bottom: 0.03125rem;
		}

		.desc {

			color: #999999;
		}
	}
}

.store {
	width: 9rem;
	height: 2.8rem;
	margin: 0 auto 0.416667rem;
	background: url("~@/assets/images/banner23.jpg") no-repeat;

	.title {
		font-weight: bold;
		font-size: 0.166667rem;
		color: #FFFFFF;
		padding-top: 0.416667rem;
		margin-bottom: 0.208333rem;
	}

	.item {
		width: 2rem;
		cursor: pointer;
		margin-right: 0.2rem;

		.imgs {
			width: 100%;
			height: 1.5rem;
			margin-bottom: 0.104167rem;
		}

		.name {
			font-size: 0.083333rem;
			color: #FFFFFF;
			text-align: center;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.TMT {
	margin-bottom: 0.416667rem;

	.title {
		font-weight: bold;
		font-size: 0.166667rem;
		color: #666;
		margin-bottom: 0.208333rem;
	}

	.imgs {
		width: 6.25rem;
		margin: 0 auto;

		img {
			max-width: 100%;
		}
	}
}
</style>
